<template>
  <MRow>
    <MCol>
      <FlotoFormItem id="notes-input" :label="$tc('note')" rules="required">
        <FlotoRichEditor
          v-model="data.notes"
          :placeholder="$t('description')"
          :max-height="200"
          :affixed-toolbar="false"
        />
      </FlotoFormItem>
      <FlotoAttachment
        id="note-attachments-input"
        v-model="data.noteAttachments"
        as-link
      />
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'NoteRuleForm',
  model: { event: 'change' },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
}
</script>
