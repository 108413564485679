<template>
  <table class="item-list-table w-full user-profile-table horizontal-list">
    <tbody>
      <tr>
        <td style="width: 250px" class="text-ellipsis">{{ $t('name') }}</td>
        <td>{{ user.name || '---' }}</td>
      </tr>
      <tr>
        <td>{{ $tc('email') }}</td>
        <td>{{ user.email || '---' }}</td>
      </tr>
      <tr>
        <td>{{ $t('logon_name') }}</td>
        <td>{{ user.userLogOnName || '---' }}</td>
      </tr>
      <tr>
        <td>{{ $tc('department') }}</td>
        <td>
          <FlotoDepartmentPicker
            text-only
            disabled
            placeholder="---"
            :value="user.department"
          />
        </td>
      </tr>
      <tr v-if="enableMsp">
        <td>{{ $tc('company') }}</td>
        <td>
          <CompanyPicker
            text-only
            disabled
            placeholder="---"
            :value="user.companyId"
          />
        </td>
      </tr>
      <tr>
        <td>{{ $tc('manager') }}</td>
        <td>
          <FlotoRequesterPicker
            text-only
            disabled
            placeholder="---"
            :value="user.managerId"
          />
        </td>
      </tr>
      <tr>
        <td>{{ $tc('location') }}</td>
        <td>
          {{ locationName }}
        </td>
      </tr>
      <tr>
        <td>{{ $tc('timezone') }}</td>
        <td class="text-ellipsis">
          <FlotoTimeZonePicker :value="user.timezone" disabled />
        </td>
      </tr>
      <tr>
        <td>{{ $tc('contact_no') }}</td>
        <td class="text-ellipsis">
          {{ user.contactNo || '---' }}
        </td>
      </tr>
      <tr>
        <td>{{ $tc('authentication_source') }}</td>
        <td class="text-ellipsis">
          {{ $tc(user.authenticationSource) }}
        </td>
      </tr>
      <tr>
        <td>{{ $tc('status') }}</td>
        <td class="text-ellipsis">
          {{ user.blocked ? $tc('blocked') : $tc('unblocked') }}
        </td>
      </tr>
      <tr v-for="row in customFormFields" :key="row.id">
        <td :title="row.text" class="text-ellipsis">{{ row.text }}</td>
        <td>{{ row.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { convertValueForDescription } from '@utils/value'
import { LocationComputed } from '@state/modules/location'
import { FormComputed } from '@state/modules/form'
import { flattenRecursive } from '@data/recursive'
import CompanyPicker from '@components/data-picker/company-picker'
import { MspConfigComputed } from '@state/modules/msp-config/helpers'

export default {
  name: 'UserProfile',
  components: { CompanyPicker },
  props: {
    user: { type: Object, required: true },
  },
  computed: {
    ...FormComputed,
    ...LocationComputed,
    ...MspConfigComputed,
    locationName() {
      if (this.user.location) {
        const location = flattenRecursive(this.locationOptions).find(
          (i) => i.id === this.user.location
        )
        return location ? location.name : '---'
      }
      return '---'
    },
    customFormFields() {
      const fields = this.userFields({
        exclude: ['section', 'label', 'upload'],
      })
      return fields.map((field) => {
        return {
          text: field.title,
          id: field.id,
          value: (this.user.fieldValueDetails || {})[field.id]
            ? convertValueForDescription(
                field.inputType,
                (this.user.fieldValueDetails || {})[field.id],
                field
              )
            : '---',
        }
      })
    },
  },
}
</script>
