import { render, staticRenderFns } from "./kanban-item.vue?vue&type=template&id=7e514563&scoped=true&"
import script from "./kanban-item.vue?vue&type=script&lang=js&"
export * from "./kanban-item.vue?vue&type=script&lang=js&"
import style0 from "./kanban-item.vue?vue&type=style&index=0&id=7e514563&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e514563",
  null
  
)

export default component.exports