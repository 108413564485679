export default {
  ID_PROPERTY: 'id',
  REQUEST: 'request',
  PROBLEM: 'problem',
  CHANGE: 'change',
  RELEASE: 'release',
  ASSET: 'asset',
  TASK: 'task',
  ADMIN: 'admin',
  APPROVAL_STAGE: 'approval_stage',
  SERVICE_CATALOG: 'service_catalog',
  ASSET_MOVEMENT: 'asset_movement',
  ASSET_CONFIGURATION: 'asset_configuration',
  ASSET_CONFIGURATION_DEPLOYMENT: 'asset_configuration_deployment',
  USER: 'user',
  FEEDBACK: 'feedback',
  KNOWLEDGE: 'knowledge',
  REPORT: 'report',
  REPORT_HISTORY: 'report_history',
  PRODUCT: 'product',
  VENDOR: 'vendor',
  ASSET_HARDWARE: 'asset_hardware',
  ASSET_SOFTWARE: 'asset_software',
  ASSET_NON_IT: 'asset_non_it',
  CONTRACT: 'contract',
  PURCHASE: 'purchase',
  USER_SURVEY: 'user_survey',
  USER_SURVEY_RESPONSE: 'user_survey_response',
  USER_SURVEY_SCHEDULE: 'user_survey_schedule',
  AGENT: 'agent',
  SOFTWARE_LICENSE: 'software_license',
  DEPARTMENT: 'department',
  LOCATION: 'location',
  MANUFACTURER: 'manufacturer',
  SNMP_PROPERTY_DEVICE: 'snmp_property_device',
  BASELINE: 'baseline',
  PRODUCTVENDOR: 'productvendor',
  CATEGORY: 'category',
  CO_OPS_CALL_HISTORY: 'co_ops_call_history',
  CO_OPS_TEAM_ACTIVITY_SUMMARY: 'co_ops_team_activity_summary',
  CONTACTOPS: 'contactops',
  DEPENDENT_VALUE: 'dependent_value',
  SINGLE_COLUMN: 'single_column',
  // patch related modules
  OSAPPLICATION: 'osapplication',
  REMOTE_OFFICE: 'remote_office',

  COMPUTER: 'computer',
  PATCH_COMPUTERS: 'patch_computers',
  PACKAGES_COMPUTERS: 'packages_computers',

  PATCH: 'patch',
  WINDOWS_PATCH: 'windows_patch',
  PROJECT: 'project',
  REGISTRY: 'registry',

  REMOTE_DEPLOYMENT: 'remote_deployment',
  PATCH_REMOTE_DEPLOYMENT: 'patch_remote_deployment',

  PACKAGE: 'pakage',
  PACKAGES_REMOTE_DEPLOYMENT: 'packages_remote_deployment',
  REGISTRY_REMOTE_DEPLOYMENT: 'registry_remote_deployment',

  DEPLOYMENT: 'deployment',
  PACKAGES: 'packages',
  REGISTRY_TEMPLATE: 'registry_template',
  DASHBOARD: 'dashboard',

  REQUESTER: 'requester',
  TECHNICIAN: 'technician',

  APPROVAL: 'approval',
  APPROVER: 'approver',
  CREDENTIAL: 'credential',
  IP_NETWORK: 'ip_network',
  CLOUD_NETWORK: 'cloud_network',
  SNMP_PROPERTY: 'snmp_property',
  AUTO_TICKET_CREATE: 'auto_ticket_create',
  AUTO_TASK_CREATE: 'auto_task_create',
  MSP_COMPANY: 'company',
}
