<template>
  <FlotoDrawerForm
    ref="form"
    :open="open"
    @submit="handleSubmit"
    @cancel="open = false"
  >
    <template v-slot:header>
      {{ $t('note_required_for_changes') }}
    </template>
    <PlainTextNoteRuleForm v-if="plainText" v-model="data" />
    <NoteRuleForm v-else v-model="data" />
    <template v-slot:actions="{ hide, submit }">
      <MButton class="mr-2" :loading="processing" @click="submit">
        {{ $t('submit') }}
      </MButton>
      <MButton variant="default" @click="hide">{{ $t('cancel') }}</MButton>
    </template>
  </FlotoDrawerForm>
</template>

<script>
import { CustomRulesComputed } from '@state/modules/custom-rules'
import NoteRuleForm from './note-rule-form'
import PlainTextNoteRuleForm from './plain-text-note-rule-form'

export default {
  name: 'NoteRules',
  components: { NoteRuleForm, PlainTextNoteRuleForm },
  props: {
    createFn: { type: Function, required: true },
    plainText: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
      data: {},
      processing: false,
    }
  },
  computed: {
    ...CustomRulesComputed,
  },
  methods: {
    openForm() {
      this.data = {}
      this.processing = false
      this.open = true
    },
    closeForm() {
      this.open = false
    },
    handleSubmit() {
      if (this.processing) {
        return
      }
      // @TODO remove interanal processing
      this.processing = true
      this.createFn(this.data).finally(() => {
        setTimeout(() => {
          this.processing = false
        }, 400)
      })
      // this.$emit('submit', this.data)
    },
  },
}
</script>
