<template>
  <MRow class="h-100 ignore-mousetrap min-w-0">
    <!-- dummy input for focus on mount -->
    <!-- <input ref="dummyInputRef" style="width: 0; border: none" /> -->
    <MCol class="flex flex-col h-100 min-w-0">
      <div v-if="operators.length" class="flex-col flex">
        <MCol>
          <div class="pt-2">
            <small class="text-neutral">{{ $t('operator') }}</small>
          </div>
          <div>
            <MCheckableTag
              v-for="o in allOperators"
              :key="o.value"
              :class="{ disabled: o.disabled }"
              :checked="currentOperator.value === o.value"
              @input="currentOperator = o"
            >
              {{ o.name }}
            </MCheckableTag>
          </div>
          <div>
            <MDivider class="my-2" />
          </div>
        </MCol>
      </div>
      <div class="flex-1 overflow-hidden" style="min-width: 300px">
        <FlotoForm
          v-if="unaryOperators.indexOf(currentOperator.value) === -1"
          ref="form"
          class="h-100 flex flex-col"
          layout="vertical"
          @submit="formSubmitted"
        >
          <FlotoScrollView>
            <MCol v-if="isDropdown">
              <FlotoFormItem
                class="m-0 p-0"
                :label="$t('value')"
                :rules="dropdownValuesValidation"
                :module-name="moduleName"
              >
                <DropdownValues
                  v-model="selectedValue"
                  :options="options"
                  :single-value-selection="
                    ['equal', 'not_equal'].indexOf(currentOperator.value) >= 0
                  "
                />
              </FlotoFormItem>
            </MCol>
            <MCol v-else-if="isDateSpecialField">
              <CreatedTime
                :value.sync="selectedValue"
                :to-value.sync="selectedToValue"
                :operator="currentOperator"
                :type="type"
                :module-name="moduleName"
              />
            </MCol>
            <MCol v-else>
              <FlotoFormItem
                vid="fromDateTime"
                :validation-label="$t('value')"
                :rules="validationRules"
                class="m-0 p-0"
              >
                <FlotoValueInput
                  v-model="selectedValue"
                  :input-type="
                    type === 'dateTime' || type === 'date'
                      ? currentOperator.value === 'after' ||
                        currentOperator.value === 'before'
                        ? 'duration'
                        : 'date'
                      : type
                  "
                  :value-type="valueType"
                  :day-time-trigger-style="{ 'mb-6': true }"
                  :get-popup-container="getPopupContainer"
                  :placeholder="placeholder"
                  :module-name="moduleName"
                  always-text-mode
                  :additional-options="additionalOptions"
                  :available-asset-type="
                    availableAssetType || searchBarContext.availableAssetType
                  "
                  v-bind="fromInputSpecificProps"
                  auto-focus
                  multiple
                />
              </FlotoFormItem>
              <template v-if="currentOperator.value === 'between'">
                <div class="mb-4 flex items-center justify-center">
                  {{ $t('to') }}
                </div>
                <FlotoFormItem
                  vid="toDateTime"
                  :validation-label="$t('value')"
                  class="m-0 p-0"
                  :rules="{
                    ...validationRules,
                    ...(type === 'number'
                      ? // eslint-disable-next-line
                        { min_value: selectedValue + 1, nonzero: true }
                      : {}),
                  }"
                >
                  <FlotoValueInput
                    v-model="selectedToValue"
                    :input-type="type"
                    :get-popup-container="getPopupContainer"
                    :value-type="valueType"
                    :placeholder="placeholder"
                    :module-name="moduleName"
                    :additional-options="additionalOptions"
                    :available-asset-type="
                      availableAssetType || searchBarContext.availableAssetType
                    "
                    auto-focus
                    multiple
                    v-bind="inputSpecificProps"
                  />
                </FlotoFormItem>
              </template>
            </MCol>
          </FlotoScrollView>
          <template v-slot:submit="{ invalid }">
            <div class="py-2">
              <MCol class="flex justify-end">
                <MButton :disabled="invalid" outline size="small" type="submit">
                  {{ $t('done') }}
                </MButton>
              </MCol>
            </div>
          </template>
        </FlotoForm>
      </div>
    </MCol>
  </MRow>
</template>

<script>
import { unaryOperators } from '@data/operator'
import DropdownValues from './value-picker/dropdown-values'
import CreatedTime from './value-picker/created-time'

export default {
  name: 'ValueInput',
  components: { DropdownValues, CreatedTime },
  inject: ['searchBarContext'],
  props: {
    type: { type: String, required: true },
    moduleName: { type: String, required: true },
    valueType: { type: String, required: true },
    paramName: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    allowDecimal: { type: Boolean, default: false },
    value: {
      type: [String, Object, Array, Number, Boolean],
      default: undefined,
    },
    toValue: { type: [String, Object, Array, Number], default: undefined },
    operator: { type: [String, Object], default: undefined },
    customFieldType: { type: String, default: undefined },
    operators: {
      type: Array,
      default() {
        return []
      },
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    additionalOptions: {
      type: Array,
      default() {
        return []
      },
    },
    availableAssetType: { type: [String, Array], default: undefined },
  },
  data() {
    this.unaryOperators = unaryOperators
    return {
      currentOperator: this.operator.value ? this.operator : this.operators[0],
      selectedValue: undefined,
      selectedToValue: undefined,
    }
  },
  computed: {
    dropdownValuesValidation() {
      const rules = {
        required: true,
        min: 1,
      }
      if (this.customFieldType === 'dropdown') {
        rules.required = false
        rules.min = 0
        rules.required_list_with_none = true
      }
      return rules
    },
    validationRules() {
      const rules = {}
      if (this.type !== 'boolean') {
        rules.required = true
      }
      if (['date', 'dateTime'].indexOf(this.type) >= 0) {
        rules.nonzero = true
      }
      return rules
    },
    allOperators() {
      // if date special field and selected variable operand
      if (this.isDateSpecialField) {
        if (this.selectedValue && /^[a-z]+/.test(this.selectedValue)) {
          return this.operators.map((o) => ({
            ...o,
            disabled: ['between', 'before', 'after'].indexOf(o.value) >= 0,
          }))
        } else {
          return this.operators.map((o) => ({ ...o, disabled: false }))
        }
      }
      return this.operators
    },
    isDropdown() {
      if (this.type === 'requester' && this.currentOperator.value === 'equal') {
        return true
      }
      return this.type === 'dropdown'
    },
    isDateSpecialField() {
      return ['createdTime', 'dueBy'].indexOf(this.type) >= 0
    },
    fromInputSpecificProps() {
      if (this.paramName === 'used_by_users') {
        return {
          keepUnassigned: true,
        }
      }
      if (['date', 'dateTime'].indexOf(this.type) >= 0) {
        return {
          maxDate:
            this.currentOperator.value === 'between'
              ? this.selectedToValue
              : undefined,
          allowClear: this.customFieldType !== 'datetime',
        }
      }
      if (this.type === 'number') {
        return {
          precision: this.allowDecimal ? 2 : 0,
        }
      }
      if (this.type === 'dependent') {
        return {
          options: this.options,
        }
      }
      return {}
    },
    inputSpecificProps() {
      if (['date', 'dateTime'].indexOf(this.type) >= 0) {
        return {
          minDate: this.selectedValue,
          allowClear: this.customFieldType !== 'datetime',
        }
      }
      // refactor requester_email to requester
      // if (this.type === 'requester_email') {
      //   return {
      //     type: 'email',
      //   }
      // }
      if (this.type === 'number') {
        return {
          precision: this.allowDecimal ? 2 : 0,
        }
      }
      return {}
    },
  },
  watch: {
    currentOperator(newValue) {
      if (newValue && unaryOperators.indexOf(newValue.value) >= 0) {
        this.$emit('selected', {
          operator: newValue,
          value: undefined,
          toValue: undefined,
        })
      }
      if (
        this.type === 'requester' &&
        this.options.length &&
        this.operators.find((o) => o.value === 'equal')
      ) {
        this.selectedValue = undefined
      }
    },
    selectedValue(newValue) {
      if (
        this.isDateSpecialField &&
        typeof newValue === 'string' &&
        ['between', 'before', 'after'].indexOf(this.currentOperator.value) >= 0
      ) {
        this.currentOperator = this.allOperators.find(
          (o) => o.value === 'equal'
        )
      }
    },
    value: {
      immediate: true,
      handler(newValue) {
        this.selectedValue = this.value
      },
    },
    toValue: {
      immediate: true,
      handler(newValue) {
        this.selectedToValue = this.toValue
      },
    },
  },
  methods: {
    getPopupContainer() {
      const e = this.$el.closest('.__panel')
      if (e) {
        return e
      }
      return document.body
    },
    formSubmitted() {
      this.$emit('selected', {
        operator: this.currentOperator,
        value: this.selectedValue,
        toValue: this.selectedToValue,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.checkbox-menu {
  display: flex;
  flex-direction: column;
}
</style>
