<template>
  <FlotoScrollDropdown
    :options="filteredSources"
    :show-no-data="false"
    @change="selectItem"
    @active-item-index-change="selectedIndex = $event"
  >
    <template v-slot:trigger>
      <MInput
        v-model="searchText"
        :max="255"
        :placeholder="searchBarPlaceholder"
        class="search-param-selector"
        @enterKey="enterHandler"
      />
    </template>
    <template v-slot:menu-item="{ item, selectItem: selectItemFromDropdown }">
      <a
        :id="`dropdown-${item.name}`"
        href="javascript:;"
        @click="selectItemFromDropdown(item)"
      >
        {{ item.name }}
      </a>
    </template>
  </FlotoScrollDropdown>
</template>

<script>
import { searchList } from '@utils/arr'

export default {
  name: 'ParamSelector',
  props: {
    sources: { type: Array, required: true },
    // eslint-disable-next-line
    allowKeywords: { type: Boolean, default: true },
    searchBarPlaceholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchText: '',
      selectedIndex: undefined,
      filteredSources: [],
    }
  },
  watch: {
    sources: 'updateFilteredSources',
    searchText: {
      immediate: true,
      handler: 'updateFilteredSources',
    },
  },
  methods: {
    reset() {
      this.searchText = ''
    },
    updateFilteredSources() {
      if (!this.searchText) {
        this.filteredSources = this.sources
        return
      }
      this.filteredSources = searchList(this.sources, this.searchText)
    },
    enterHandler(e) {
      e.preventDefault()
      e.stopPropagation()
      if (!this.allowKeywords) {
        return
      }
      if (this.searchText && this.searchText.length > 255) {
        return this.$errorNotification({
          message: this.$t('error'),
          description: this.$t('search_keyword_validation_message'),
        })
      }
      if (this.filteredSources.length) {
        // here the item is selected
        this.selectItem(this.filteredSources[this.selectedIndex])
      } else {
        // here its new keyword
        this.selectItem({ keyword: this.searchText })
      }
    },
    selectItem(sourceKey) {
      if (typeof sourceKey === 'object' && 'keyword' in sourceKey) {
        this.$emit('selected', sourceKey)
      } else {
        const source = this.filteredSources.find((s) => s.key === sourceKey)
        this.$emit('selected', source)
      }
    },
  },
}
</script>

<style lang="less">
.search-param-selector,
.search-param-selector > input {
  padding: 0;
  background-color: var(--page-background-color);
  border: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>
