<template>
  <FlotoDrawer width="70%" :open="open" @hide="$emit('close')" @show="getTask">
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"><span /></slot>
    </template>
    <template v-if="task" v-slot:title>
      <h5 class="mb-0"> {{ task.name }}: {{ task.subject }} </h5>
    </template>
    <MRow v-if="task">
      <MCol class="min-w-0 flex flex-1">
        <MTab
          default-active="details"
          size="small"
          class="flex flex-1 flex-col"
          @change="handleTabChange"
        >
          <MTabPane key="details" :tab="$t('details')">
            <Task
              :allow-edit="false"
              :allow-delete="false"
              :task="task"
              :disabled="disabled"
              :is-in-active-task="hideConversation"
              v-on="taskListeners"
            >
              <template v-slot:task-actions>
                <span />
              </template>
            </Task>
            <div v-if="relatedResourceType" class="my-2 bordered rounded">
              <ResourceListTitleWithStatus
                :module-name="relatedResourceType"
                bordered
                :use-link="!isPortalLogin"
                :link="resourceLink"
                :resource="relatedResource"
              />
            </div>
            <div v-if="task.description">
              <small class="text-neutral-light">{{ $t('description') }}</small>
              <div class="mt-2">
                <FlotoRichEditor :value="task.description" disabled />
              </div>
            </div>
            <div v-if="(task.fileAttachemnts || {}).length">
              <FlotoAttachment :value="task.fileAttachemnts" as-link disabled />
            </div>
          </MTabPane>
          <MTabPane
            v-if="hideConversation === false"
            key="comments"
            :tab="$tc('comment', 2)"
          >
            <ConversationContainer
              :disabled="disabled"
              :allowed-types="['comment_on_task']"
              module-name="task"
              :use-private-indicator="false"
              :resource-id="task.id"
            />
          </MTabPane>
          <MTabPane
            v-if="hideWork === false"
            key="worklog"
            :tab="$t('work_log')"
          >
            <WorkLogContainer
              :disabled="disabled"
              module-name="task"
              :resource-id="task.id"
            >
              <template
                v-if="task.moduleName === $constants.PROJECT"
                v-slot:before-add-controls
              >
                <MTooltip v-if="task.estimatedTime" class="mr-2">
                  <template v-slot:trigger>
                    <MIcon
                      name="hourglass"
                      class="mr-1 text-neutral-light text-base"
                    />
                    <span>
                      {{
                        (
                          Math.ceil(task.estimatedTime / 60 / 60 / 1000) || 0
                        ).toFixed(2)
                      }}
                      {{ $tc('hours', 2) }}
                    </span>
                  </template>
                  {{ $t('estimates') }} ({{ $tc('hours', 2) }})
                </MTooltip>
                <MTooltip v-if="worklogTotalTime" class="mr-2">
                  <template v-slot:trigger>
                    <MIcon
                      name="hourglass"
                      class="mr-1 text-neutral-light text-base"
                    />
                    <span>
                      {{
                        (
                          Math.ceil(worklogTotalTime / 60 / 60 / 1000) || 0
                        ).toFixed(2)
                      }}
                      {{ $tc('hours', 2) }}
                    </span>
                  </template>
                  {{ $t('worklog') }}
                  {{ $t('time', { type: $t('total') }) }} ({{
                    $tc('hours', 2)
                  }})
                </MTooltip>
                <MTooltip class="mr-2">
                  <template v-slot:trigger>
                    <MIcon
                      name="clock"
                      class="mr-1 text-neutral-light text-base"
                    />
                    <span>
                      {{
                        Math.ceil(
                          (task.estimatedTime - worklogTotalTime) /
                            60 /
                            60 /
                            1000
                        )
                      }}
                      {{ $tc('hours', 2) }}
                    </span>
                  </template>
                  {{ $t('remaining') }} {{ $tc('time') }} ({{
                    $tc('hours', 2)
                  }})
                </MTooltip>
              </template>
            </WorkLogContainer>
          </MTabPane>
          <MTabPane
            v-if="task.moduleName === $constants.PROJECT"
            key="checklist"
            :tab="$t('check_list')"
          >
            <CheckListContainer
              :disabled="disabled"
              module-name="task"
              :resource-id="task.id"
            />
          </MTabPane>
          <MTabPane key="audit" :tab="$t('audit_trail')">
            <AuditContainer
              ref="auditContainer"
              module-name="task"
              :resource-id="task.id"
            />
          </MTabPane>
        </MTab>
      </MCol>
    </MRow>
    <template v-slot:actions="{ hide }">
      <slot name="actions" :hide="hide">
        <FlotoDeleteBtn
          v-if="!disabled && task.moduleName !== $constants.PROJECT"
          :message="$t('confirm_delete_item', { item: $tc('task') })"
          :show-tooltip="false"
          @confirm="$emit('remove')"
        >
          <MButton variant="error" outline>
            {{ $t('delete') }}
          </MButton>
        </FlotoDeleteBtn>
        <MButton variant="default" class="mx-1" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </slot>
    </template>
  </FlotoDrawer>
</template>

<script>
import ConversationContainer from '@components/conversation/conversation-container'
import WorkLogContainer from '@components/worklog/worklog-container'
import AuditContainer from '@components/audit/audit-container'
import CheckListContainer from '@components/check-list/check-list-container'
import { getTaskResourceDetailApi } from '@modules/ticket/ticket-api'
import ResourceListTitleWithStatus from '@components/resource/resource-list-title-with-status.vue'
import Task from './task'
import { getTaskApi, getWorklogTotalTimeApi } from './api'
import { authComputed } from '@state/modules/auth'

export default {
  name: 'FlotoTaskDrawer',
  components: {
    Task,
    ConversationContainer,
    WorkLogContainer,
    AuditContainer,
    ResourceListTitleWithStatus,
    CheckListContainer,
  },
  props: {
    defaultTask: { type: Object, default: undefined },
    taskId: { type: Number, required: false, default: undefined },
    parentId: { type: Number, default: undefined },
    moduleName: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    hideConversation: { type: Boolean, default: false },
    hideWork: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
  },
  data() {
    return {
      task: {
        ...(this.defaultTask || {}),
      },
      relatedResource: {},
      relatedResourceType: undefined,
      worklogTotalTime: undefined,
    }
  },
  computed: {
    ...authComputed,
    taskListeners() {
      const { close, ...listeners } = this.$listeners
      return listeners
    },
    resourceLink() {
      if (
        [
          this.$constants.REQUEST,
          this.$constants.PROBLEM,
          this.$constants.CHANGE,
        ].indexOf(this.moduleName || this.defaultTask.moduleName) >= 0
      ) {
        return this.$modules.getModuleRoute('ticket', 'view', {
          params: {
            ticketType: this.moduleName || this.defaultTask.moduleName,
            id: this.task.parentId,
          },
        })
      }
      return undefined
    },
  },
  watch: {
    defaultTask(newValue) {
      if ((newValue || {}).id) {
        this.task = { ...newValue }
      }
    },
  },
  methods: {
    getTask() {
      if (this.task.id) {
        this.getRelatedResource()
        if (this.task.moduleName === this.$constants.PROJECT) {
          this.getWorklogTotalTime()
        }
        return
      }
      getTaskApi(this.moduleName, this.parentId, this.taskId).then((data) => {
        this.task = data
        this.getRelatedResource()
      })
    },
    getRelatedResource() {
      if (this.task.parentId) {
        getTaskResourceDetailApi(this.task.moduleName, this.task.parentId).then(
          (data) => {
            this.relatedResource = data
            this.relatedResourceType =
              this.task.moduleName || this.moduleName || this.$constants.REQUEST
          }
        )
      }
    },
    handleTabChange(tab) {
      if (tab === 'audit') {
        this.$refs &&
          this.$refs.auditContainer &&
          this.$refs.auditContainer.refresh()
      }
    },
    getWorklogTotalTime() {
      getWorklogTotalTimeApi(this.task.id).then((data) => {
        this.worklogTotalTime = data
      })
    },
  },
}
</script>
