<template>
  <FlotoDrawer :open="isOpen" :scrolled-content="false" @hide="isOpen = false">
    <template v-slot:trigger>
      <a @click="handleOpen">
        <MBadge :count="count">
          <!-- <MTooltip placement="right">
            <template v-slot:trigger> -->
          <MButton
            variant="neutral-light"
            :shadow="false"
            shape="circle"
            class="text-neutral text-base"
          >
            <MIcon name="bell" />
          </MButton>
          <!-- </template>
            <span>{{ $t('notifications') }}</span>
          </MTooltip> -->
        </MBadge>
      </a>
    </template>
    <template v-slot:title>
      {{ $t('notifications') }}
    </template>
    <MRow class="h-100">
      <MCol class="flex flex-col h-100">
        <div class="mr-2 flex-col flex">
          <div class="flex justify-end mb-2">
            <NotificationSetting />
          </div>
        </div>
        <FlotoScrollView>
          <div class="mr-2 flex-col flex">
            <MRow :gutter="0" class="flex-1 text-left">
              <MCol class="flex flex-col">
                <FlotoCrudContainer
                  ref="crudContainer"
                  :fetch-fn="getNotifications"
                  :per-page="20"
                  :scrollable="false"
                  :update-fn="updateNotifications"
                  :delete-fn="deleteNotifications"
                  :paging="false"
                >
                  <template v-slot:add-controls="{ items }">
                    <MRow v-if="items.length" class="mb-2" :gutter="0">
                      <MCol :size="12" class="flex justify-end">
                        <div style="display: inline-flex">
                          <a
                            class="text-right mb-1 text-xs"
                            @click="markAllAsRead"
                          >
                            {{ $tc('mark_all_as_read') }}
                          </a>
                          <span class="mx-1 text-primary">|</span>
                          <a
                            class="text-right mb-1 text-xs"
                            @click="clearAllNotification"
                          >
                            {{ $tc('clear_all') }}
                          </a>
                        </div>
                      </MCol>
                    </MRow>
                  </template>
                  <template v-slot:item="{ item, edit, update, remove }">
                    <div class="hierarchy-item flex items-center">
                      <div
                        class="notification-item-container flex flex-1 items-center min-w-0 mb-2 mr-0 with-bg"
                      >
                        <Item
                          :item="item"
                          type="notification"
                          @remove="remove(item)"
                          @markAsRead="markAsRead(item, update)"
                        />
                      </div>
                    </div>
                  </template>
                </FlotoCrudContainer>
              </MCol>
            </MRow>
          </div>
        </FlotoScrollView>
      </MCol>
    </MRow>
    <template v-slot:actions="{ hide }">
      <MButton variant="default" @click="hide">
        {{ $t('close') }}
      </MButton>
    </template>
  </FlotoDrawer>
</template>

<script>
import NotificationSetting from './notification-setting'
import {
  getNotificationsApi,
  removeNotificationsApi,
  markAsReadNotificationsApi,
  markAllAsReadNotificationsApi,
  clearAllNotificationsApi,
} from './notification-support-api'
import Item from './single-item'

export default {
  name: 'NotificationSupport',
  components: {
    NotificationSetting,
    Item,
  },
  props: {
    count: { type: Number, default: 0 },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    handleOpen() {
      this.isOpen = true
      this.$emit('onUpdateCount')
    },
    getNotifications(pageSize, offset) {
      return getNotificationsApi(pageSize, offset)
    },
    deleteNotifications(item) {
      return removeNotificationsApi(item)
    },
    markAsRead(item, updateFn) {
      return updateFn(item)
    },
    updateNotifications(item) {
      return markAsReadNotificationsApi(item)
    },
    markAllAsRead() {
      return markAllAsReadNotificationsApi().then(() => this.refresh())
    },
    clearAllNotification() {
      return clearAllNotificationsApi().then(() => this.refresh())
    },
    refresh() {
      this.$refs.crudContainer.refresh()
    },
  },
}
</script>

<style lang="less" scoped>
.hierarchy-item {
  .notification-item-container {
    @apply border-black;
    // z-index: 1;
    min-height: 40px;
    padding: 0.5rem 0.75rem;
    margin-right: 0;
    font-weight: 400;
    background: rgba(238, 242, 246, 0.4);
    border-radius: 0.25rem;

    &:hover {
      @apply bg-neutral-lightest cursor-pointer;
    }
  }
}
</style>
