<template>
  <div class="item-action" :class="{ 'sticky-action': actionSticked }">
    <slot :stickAction="stickAction" :unstickAction="unStickAction"></slot>
  </div>
</template>

<script>
export default {
  name: 'HoverableAction',
  data() {
    return {
      actionSticked: false,
    }
  },
  methods: {
    stickAction() {
      this.actionSticked = true
    },
    unStickAction() {
      this.actionSticked = false
    },
  },
}
</script>
