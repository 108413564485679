<template>
  <span>
    <slot
      v-if="!isOpen"
      name="trigger"
      :show="handleShow"
      :hide="handleHide"
      :toggle="handleToggle"
      :current-item="selectedItem"
    >
      <DropdownTrigger
        v-if="!multiple"
        :toggle="handleToggle"
        :is-open="isOpen"
        :selected-item="selectedItem"
        :allow-clear="allowClear"
        :text-only="textOnly"
        :disabled="disabled"
        :as-input="asInput"
        :size="size"
        :input-classes="inputClasses"
        :placeholder="placeholder"
        @reset="handleChange(undefined)"
      >
        <template v-slot:prefix-text="{ item }">
          <slot name="trigger-prefix-text" :item="item"></slot>
        </template>
        <template
          v-if="fullPath && !multiple && value && displayFullPath"
          v-slot:bellow-input
        >
          <div class="text-neutral-light text-xs">
            {{ displayFullPath }}
          </div>
        </template>
      </DropdownTrigger>
      <MultipleTrigger
        v-else
        :allow-clear="allowClear"
        :selected-items="selectedItem"
        :disabled="disabled"
        :placeholder="placeholder"
        :options="flattenRecursive(options)"
        @click="handleToggle"
        @change="handleChange"
      />
    </slot>
    <MPopover
      v-else
      ref="dropdownPopoverRef"
      default-open
      :placement="popoverPlacment"
      :get-popup-container="getPopupContainer"
      :disabled="disabled"
      transition-name="slide-up"
      overlay-class-name="picker-overlay"
      v-bind="attrs"
      @hide="handleHide"
      @show="handleShow"
      v-on="listeners"
    >
      <template v-slot:trigger>
        <slot
          name="trigger"
          :show="handleShow"
          :hide="handleHide"
          :toggle="handleToggle"
          :current-item="selectedItem"
        >
          <DropdownTrigger
            v-if="!multiple"
            :toggle="handleToggle"
            :is-open="isOpen"
            :selected-item="selectedItem"
            :allow-clear="allowClear"
            :text-only="textOnly"
            :disabled="disabled"
            :as-input="asInput"
            :size="size"
            :input-classes="inputClasses"
            :placeholder="placeholder"
            @reset="handleChange(undefined)"
          >
            <template v-slot:prefix-text="{ item }">
              <slot name="trigger-prefix-text" :item="item"></slot>
            </template>
            <template
              v-if="fullPath && !multiple && value && displayFullPath"
              v-slot:bellow-input
            >
              <div class="text-neutral-light text-xs">
                {{ displayFullPath }}
              </div>
            </template>
          </DropdownTrigger>
          <MultipleTrigger
            v-else
            :allow-clear="allowClear"
            :selected-items="selectedItem"
            :disabled="disabled"
            :placeholder="placeholder"
            :options="flattenRecursive(options)"
            @click="handleToggle"
            @change="handleChange"
          />
        </slot>
      </template>
      <div class="flex flex-col h-100 min-h-0">
        <div ref="scrollContainer" class="flex h-100 min-h-0 flex-col">
          <div v-if="searchable" class="my-2 px-2">
            <MInput
              ref="searchBox"
              v-model="searchTerm"
              :placeholder="$tc('search')"
            >
              <template v-slot:suffix>
                <MIcon name="search" />
              </template>
            </MInput>
          </div>
          <div
            v-if="multiple && selectedItem.length"
            class="mb-2 px-2"
            :class="{
              'dropdown-selected-items-container': selectedItem.length > 4,
            }"
          >
            <div class="text-primary mb-1">
              {{ $tc('selected') }} {{ $tc('item', 2) }}
            </div>
            <MultipleTrigger
              :allow-clear="false"
              :selected-items="selectedItem"
              disabled
              as-tag
              can-remove-selected-items-pill
              display-all-selected-items-pill
              :options="flattenRecursive(options)"
              :as-input="false"
              v-bind="attrs"
              :input-classes="inputClasses"
              @change="handleChange"
            />
            <MDivider class="mb-0 mt-1" />
          </div>
          <slot name="before-menu"></slot>
          <InfiniteTree
            v-if="isLargDataSet && options.length"
            ref="infinieTreeRef"
            class="py-1 px-1 tree-list-div"
            :data="options"
            :search-term="searchTerm"
            :with-bg="false"
            :row-height="40"
            :node-fields="['name']"
            :value="selectedItem"
            :multiple="multiple"
            :only-leaf-node-selectable="onlyLeafNodeSelectable"
            :hidden-options-keys="hiddenOptionsKeys"
            :visible-options-keys="visibleOptionsKeys"
            @change="changeHandler"
          >
            <template v-slot="{ item }">
              <slot name="item" :item="item" :disabled="onlyLeafNodeSelectable">
                <MTooltip>
                  <template v-slot:trigger>
                    <span class="min-w-0 text-ellipsis">{{ item.name }}</span>
                  </template>
                  {{ item.name }}
                </MTooltip>
              </slot>
            </template>
          </InfiniteTree>
          <FlotoScrollView v-if="!isLargDataSet && currentOptions.length">
            <div class="px-2 py-1 ml-5 tree-list-view">
              <TreeList
                :options="currentOptions"
                :value="value"
                :highlight-term="searchTerm"
                :multiple="multiple"
                :show-no-data="showNoData"
                :level-margin="levelMargin"
                :only-leaf-node-selectable="onlyLeafNodeSelectable"
                @change="handleChange"
              >
                <template v-slot:item="{ item, select }">
                  <slot
                    name="item"
                    :item="item"
                    :select="select"
                    :disabled="onlyLeafNodeSelectable"
                  >
                  </slot>
                </template>
              </TreeList>
            </div>
          </FlotoScrollView>
          <slot name="after-menu"></slot>
        </div>
      </div>
    </MPopover>
  </span>
</template>

<script>
import Bus from '@utils/emitter'
import InfiniteTree from '@components/hierarchy/infinite-tree.vue'
import {
  flattenRecursive,
  findValueObject,
  searchRecursive,
  collapseAll,
  expandeSelected,
  findValuePathWithItems,
} from '@data/recursive'
import { authComputed } from '@state/modules/auth'
import CloneDeep from 'lodash/cloneDeep'
import DropdownTrigger from '../dropdown-trigger.vue'
import MultipleTrigger from './multiple-trigger.vue'
import TreeList from './tree-list'

export default {
  name: 'TreePicker',
  components: { DropdownTrigger, MultipleTrigger, InfiniteTree, TreeList },
  model: { event: 'change' },
  props: {
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$tc('select')
      },
    },
    inputClasses: { type: [Array, Object, String], default: undefined },
    textOnly: { type: Boolean, default: false },
    // eslint-disable-next-line
    allowClear: { type: Boolean, default: true },
    size: { type: String, default: undefined },
    asInput: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    value: { type: [Array, Number, Object, String], default: undefined },
    unassignedValue: { type: [String, Number], default: 0 },
    // eslint-disable-next-line
    showNoData: { type: Boolean, default: true },
    // eslint-disable-next-line
    isLargDataSet: { type: Boolean, default: true },
    levelMargin: { type: Number, default: 20 },
    onlyLeafNodeSelectable: { type: Boolean, default: false },
    fullPath: { type: Boolean, default: false },
    hiddenOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    visibleOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    focusEventBrodcast: { type: Boolean, default: false },
  },
  data() {
    this.flattenRecursive = flattenRecursive
    return {
      currentOptions: CloneDeep(this.options),
      searchTerm: '',
      isOpen: false,
    }
  },
  computed: {
    ...authComputed,
    popoverPlacment() {
      return this.isRtl ? 'bottomRight' : 'bottomLeft'
    },
    selectedItem() {
      if (this.multiple) {
        return this.value || []
      } else {
        if (this.value || this.value === 0) {
          return findValueObject(this.options, this.value)
        }
      }
      return undefined
    },
    displayFullPath() {
      if (this.fullPath && this.value && !this.multiple) {
        const path = findValuePathWithItems(this.options, this.value)
        return path.map((p) => p.name).join(' > ')
      }
      return null
    },
    listeners() {
      const { change, hide, show, ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const { ...attrs } = this.$attrs
      return attrs
    },
  },
  watch: {
    searchTerm(newValue) {
      if (!this.isLargDataSet) {
        if (newValue) {
          this.currentOptions = searchRecursive(newValue, this.options)
        } else {
          if (this.value) {
            this.currentOptions = expandeSelected(this.value, this.options)
          } else {
            this.currentOptions = collapseAll(this.options)
          }
        }
      }
    },
    currentOptions(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.dispatchEvent(new Event('resize'))
      }
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.currentOptions = expandeSelected(newValue, this.options)
        } else {
          this.currentOptions = collapseAll(this.options)
        }
      },
    },
  },
  created() {
    const openPopover = (id) => {
      if (id === this.$attrs.id) {
        this.handleShow(true)
      } else {
        this.handleHide(true)
      }
    }
    Bus.$on('app:popover:broadcast:open', openPopover)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('app:popover:broadcast:open', openPopover)
    })
  },
  methods: {
    getPopupContainer() {
      const element = this.focusEventBrodcast
        ? this.$el.closest('.single-control')
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    handleShow(skipBroadcast = false) {
      if (this.disabled) {
        return
      }
      if (this.$refs.dropdownPopoverRef) {
        this.$refs.dropdownPopoverRef.show()
      }
      this.isOpen = true
      if (this.$attrs.id && !skipBroadcast) {
        Bus.$emit('app:single:dropdown:open', this.$attrs.id)
      }
      this.onDropdownShow()
    },
    handleHide(skipBroadcast = false) {
      if (this.$refs.dropdownPopoverRef) {
        this.$refs.dropdownPopoverRef.hide()
      }
      this.isOpen = false
      if (this.$attrs.id && !skipBroadcast) {
        Bus.$emit('app:single:dropdown:close', this.$attrs.id)
      }
    },
    handleToggle() {
      if (this.isOpen) {
        this.handleHide()
      } else {
        this.handleShow()
      }
    },
    onDropdownShow(skipBroadcast = false) {
      if (this.disabled) {
        return
      }
      this.searchTerm = ''
      setTimeout(() => {
        this.$refs.searchBox && this.$refs.searchBox.focus()
      }, 100)
    },
    changeHandler(item) {
      // check leaf node only selection
      if (this.isDisabled(item) || this.isSelected(item)) {
        return
      }
      if (this.multiple) {
        const flattenedOptions = this.flattenRecursive(this.currentOptions)
        this.$emit(
          'selected',
          [...(this.value || []), item.id].map((id) =>
            flattenedOptions.find((o) => o.id === id)
          )
        )
        this.$emit('change', [...(this.value || []), item.id])
        this.$emit('blur')
      } else {
        this.$emit('selected', item)
        this.$emit('change', item.id || this.unassignedValue)
        this.$emit('blur')
      }
      if (!this.multiple) {
        this.handleHide()
      }
    },
    isSelected(option) {
      const value = this.value
      if (!value) {
        return false
      }
      return Array.isArray(value)
        ? value.indexOf(option.id) >= 0
        : value === option.id
    },
    isDisabled(option) {
      return (option.children || []).length && this.onlyLeafNodeSelectable
    },
    handleChange(value) {
      const flattenedOptions = this.flattenRecursive(this.currentOptions)
      if (this.multiple) {
        this.$emit(
          'selected',
          value.map((id) => flattenedOptions.find((o) => o.id === id))
        )
        this.$emit('change', value)
        this.$emit('blur')
      } else {
        this.$emit(
          'selected',
          flattenedOptions.find((o) => o.id === value)
        )
        this.$emit('change', value || this.unassignedValue)
        this.$emit('blur')
      }
      if (!this.multiple) {
        this.handleHide()
      }
    },
  },
}
</script>
<style lang="less">
.tree-list-div {
  .hierarchy-item {
    &:hover,
    &.active {
      color: var(--dropdown-selected-text);
      background: var(--dropdown-hover-bg);
    }

    &.disabled {
      background: unset;
      opacity: 0.5;
    }
  }
}
</style>
