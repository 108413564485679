<template>
  <MRow class="px-4">
    <MCol>
      <MRow>
        <MCol :sm="6" :size="12">
          <FlotoFormItem
            id="technician-picker"
            rules="required|nonzero"
            :label="$tc('technician')"
          >
            <FlotoTechnicianPicker
              v-model="worklog.technicianId"
              mandatory-selection
              required
              as-input
            />
          </FlotoFormItem>
        </MCol>
        <MCol>
          <FlotoFormItem id="time-taken-input" :label="$tc('time_taken')">
            {{ worklog.timeTakenDisplay }}
            {{ $tc('hours', worklog.timeTakenDisplay) }}
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MRow>
        <MCol>
          <FlotoFormItem
            id="start-date-picker"
            :label="`${$t('start')} ${$t('date')}`"
            rules="required|nonzero"
          >
            <FlotoDatePicker
              v-model="worklog.timeRange.startTime"
              :max-date="worklog.timeRange.endTime"
            />
          </FlotoFormItem>
        </MCol>
        <MCol>
          <FlotoFormItem
            id="end-date-picker"
            :label="`${$t('end')} ${$t('date')}`"
            rules="required|nonzero"
          >
            <FlotoDatePicker
              v-model="worklog.timeRange.endTime"
              :min-date="worklog.timeRange.startTime"
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MRow>
        <MCol>
          <FlotoFormItem
            id="description-input"
            v-model="worklog.description"
            :label="$t('description')"
            rules="description"
            auto-focus
            type="textarea"
            :placeholder="$t('description')"
          />
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
import Moment from 'moment'

export default {
  name: 'WorkLogForm',
  props: {
    worklog: { type: Object, required: true },
    groupId: { type: Number, default: 0 },
  },
  watch: {
    'worklog.timeRange.endTime': {
      immediate: true,
      handler() {
        this.calculateTimeTaken()
      },
    },
    'worklog.timeRange.startTime': {
      immediate: true,
      handler() {
        this.calculateTimeTaken()
      },
    },
  },
  methods: {
    calculateTimeTaken() {
      if (this.worklog.timeRange) {
        if (
          this.worklog.timeRange.startTime &&
          this.worklog.timeRange.endTime
        ) {
          const diff =
            this.worklog.timeRange.endTime - this.worklog.timeRange.startTime
          this.worklog.timeTakenDisplay = Math.ceil(
            Moment.duration(diff, 'milliseconds').asHours()
          )
          this.worklog.timeTaken = Math.ceil(diff)
        }
        return 0
      }
      return 0
    },
  },
}
</script>
