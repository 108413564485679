<template>
  <div v-if="shouldDropdownRender" class="box header-btn-hide-text">
    <FlotoDropdownPicker
      v-if="shouldDropdownRender"
      id="create-new-btn"
      :options="createOptions"
      placement="bottomRight"
      overlay-class-name="headerCreateMenu"
      avoid-keyboard-navigationa
      @change="handleChange"
    >
      <template v-slot:trigger="{ toggle }">
        <MButton id="create-new-btn" class="flex items-center" @click="toggle">
          {{ $t('create') }} {{ $t('new') }}
          <MIcon name="plus" size="lg" class="mx-1" />
        </MButton>
      </template>
      <template
        v-if="createOptions.length"
        v-slot:menu-item="{ item, selectItem }"
      >
        <a :id="item.key" @click.stop="selectItem(item)">
          <div class="flex">
            <div class="mx-2">
              <MIcon :name="item.icon || 'question'" size="lg" />
            </div>
            <div>
              {{ item.name }}
            </div>
          </div>
        </a>
      </template>
    </FlotoDropdownPicker>
  </div>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'

export default {
  name: 'CreateDropdown',
  props: { portalMode: { type: Boolean, default: false } },
  computed: {
    ...authComputed,
    ...LicenseComputed,
    ...PreferenceComputed,
    ...SupportPortalConfigComputed,
    shouldDropdownRender() {
      if (!this.createOptions.length) {
        return false
      }
      return this.portalMode ? this.loggedIn : true
    },
    options() {
      const options = [
        {
          key: 'request',
          icon: 'ticket-alt',
          name: `${this.$t('create')} ${this.$tc('incident', 1)}`,
          moduleKey: this.$constants.REQUEST,
          route: this.$modules.getModuleRoute('ticket', 'create', {
            params: { ticketType: this.$constants.REQUEST },
          }),
          isModuleAvailable: (allAvailableModule) =>
            allAvailableModule.indexOf(this.$constants.REQUEST) >= 0,
        },
        {
          key: 'service_request',
          icon: 'map',
          name: `${this.$tc('request')} ${this.$tc('service')}`,
          route: this.$modules.getModuleRoute('service-catalog', 'request'),
          moduleKey: this.$constants.REQUEST,
          isModuleAvailable: (allAvailableModule) =>
            allAvailableModule.indexOf(this.$constants.SERVICE_CATALOG) >= 0,
        },
        {
          key: 'problem',
          icon: 'problem',
          name: `${this.$t('create')} ${this.$tc('problem', 1)}`,
          moduleKey: this.$constants.PROBLEM,
          route: this.$modules.getModuleRoute('ticket', 'create', {
            params: { ticketType: this.$constants.PROBLEM },
          }),
          isModuleAvailable: (allAvailableModule) =>
            allAvailableModule.indexOf(this.$constants.PROBLEM) >= 0,
        },
        {
          key: 'change',
          icon: 'change',
          name: `${this.$t('create')} ${this.$tc('change', 1)}`,
          route: this.$modules.getModuleRoute('ticket', 'create', {
            params: { ticketType: this.$constants.CHANGE },
          }),
          moduleKey: this.$constants.CHANGE,
          isModuleAvailable: (allAvailableModule) =>
            allAvailableModule.indexOf(this.$constants.CHANGE) >= 0,
        },
        {
          key: 'release',
          icon: 'box-full',
          name: `${this.$t('create')} ${this.$tc('release', 1)}`,
          route: this.$modules.getModuleRoute('ticket', 'create', {
            params: { ticketType: this.$constants.RELEASE },
          }),
          moduleKey: this.$constants.RELEASE,
          isModuleAvailable: (allAvailableModule) =>
            allAvailableModule.indexOf(this.$constants.RELEASE) >= 0,
        },
        {
          key: 'asset',
          icon: 'laptop',
          name: `${this.$t('add')} ${this.$tc('asset', 1)}`,
          route: this.$modules.getModuleRoute('asset', 'create'),
          moduleKey: this.$constants.ASSET,
          isModuleAvailable: (allAvailableModule) =>
            allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
        },
        {
          key: 'purchase',
          icon: 'shopping-cart',
          name: `${this.$tc('create')} ${this.$tc('purchase')} ${this.$tc(
            'order'
          )}`,
          route: this.$modules.getModuleRoute('purchase', 'create'),
          moduleKey: this.$constants.PURCHASE,
          isModuleAvailable: (allAvailableModule) =>
            allAvailableModule.indexOf(this.$constants.PURCHASE) >= 0,
        },
        {
          key: 'project',
          icon: 'project-diagram',
          name: `${this.$t('create')} ${this.$tc('project')}`,
          route: this.$modules.getModuleRoute('project', 'create'),
          moduleKey: this.$constants.PROJECT,
          isModuleAvailable: (allAvailableModule) =>
            allAvailableModule.indexOf(this.$constants.PROJECT) >= 0,
        },
      ]
      if (this.portalMode) {
        // update menu if the menu is for portal
        return options
          .filter((o) => ['request', 'service_request'].indexOf(o.key) >= 0)
          .map((i) => ({
            ...i,
            route: this.$modules.getModuleRoute(
              'support-portal',
              i.key === 'service_request' ? 'service-list' : 'create-request'
            ),
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(
                i.key === 'service_request'
                  ? this.allowRequesterToAccessServiceRequest
                    ? this.$constants.SERVICE_CATALOG
                    : null
                  : this.$constants.REQUEST
              ) >= 0,
            moduleKey: null,
          }))
      }
      return options
    },
    createOptions() {
      return this.options
        .filter((o) => {
          if (o.isModuleAvailable) {
            return o.isModuleAvailable(this.availableModulesInLicense)
          }
          return true
        })
        .filter((m) => {
          if (m.moduleKey) {
            return this.myAllowedModules.indexOf(m.moduleKey) >= 0
          }
          return true
        })
    },
  },
  methods: {
    handleChange(v) {
      const item = this.createOptions.find((o) => o.key === v)
      if (item.route) {
        this.$router.push(item.route)
      }
    },
  },
}
</script>

<style lang="less">
.headerCreateMenu {
  .@{ant-prefix}-dropdown-menu {
    min-width: 250px !important;
    margin-top: 10px;

    @apply border-solid border-b-8 border-secondary-yellow border-l-0 border-r-0 border-t-0 rounded-lg;
  }
}
</style>
