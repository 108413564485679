<template>
  <div class="flex flex-1 items-center ">
    <MRow :style="{ flex: 1, alignItems: 'center' }">
      <MCol :size="12">
        <div class="flex flex-grow justify-between items-start min-w-0">
          <FlotoUserAvatar
            show-tooltip
            :size="35"
            style="min-width: 30px;"
            class="mt-2 mr-3 mb-0"
            :user-id="item.performerType === 'user' ? item.performerId : 0"
          />
          <div class="flex flex-col flex-1 min-w-0">
            <div
              class="flex items-center mr-1 mt-1 cursor-pointer"
              :class="item.read ? 'font-normal' : ' font-semibold'"
            >
              <NotificationTitle :text="item.title" :links="item.links" />
            </div>
            <div
              class="flex mt-1 justify-between text-neutral-light font-normal"
            >
              <div class="text-xs">
                {{ item.createdTime | datetime }} ({{
                  item.createdTime | timeago
                }})
              </div>
              <div
                v-if="!isPortalLogin && item.metadata.objectData.statusId"
                :style="{ marginRight: '-36px' }"
              >
                <FlotoStatusPicker
                  :disabled="true"
                  :value="item.metadata.objectData.statusId"
                  :module-name="getModuleName"
                />
              </div>
            </div>
          </div>
          <HoverableAction v-if="!disabled">
            <template v-slot="{ stickAction, unstickAction }">
              <FlotoDeleteBtn
                :message="
                  $t('confirm_remove_item', {
                    item: `${$tc(type)}`,
                  })
                "
                @trigger="stickAction"
                @cancel="unstickAction"
                @confirm="$emit('remove', item)"
              >
                <MIcon
                  name="times"
                  class="cursor-pointer text-neutral-light"
                ></MIcon>
              </FlotoDeleteBtn>
            </template>
          </HoverableAction>
          <MTooltip placement="left">
            <template v-slot:trigger>
              <MIcon
                name="circle"
                :type="item.read ? 'fas' : 'fad'"
                class="ml-2 mt-1 cursor-pointer"
                :class="item.read ? 'text-neutral-lighter' : 'text-primary'"
                @click="$emit('markAsRead', item)"
              ></MIcon>
            </template>
            <span>{{
              item.read ? $tc('mark_as_unread') : $tc('mark_as_read')
            }}</span>
          </MTooltip>
        </div>
      </MCol>
    </MRow>
  </div>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import HoverableAction from '@components/hoverable-action'
import NotificationTitle from './notification-title'

export default {
  name: 'Item',
  components: { HoverableAction, NotificationTitle },
  model: {
    event: 'change',
  },
  props: {
    item: { type: Object, required: true },
    type: { type: String, default: 'item' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...authComputed,
    getModuleName() {
      if (
        [
          this.$constants.ASSET_HARDWARE,
          this.$constants.ASSET_SOFTWARE,
          this.$constants.ASSET_NON_IT,
        ].indexOf(this.item.refModel) >= 0
      ) {
        return this.$constants.ASSET
      }
      return this.item.refModel
    },
  },
  methods: {},
}
</script>
