<template>
  <MPopover
    v-if="user"
    ref="popoverMenu"
    :destroy-tooltip-on-hide="false"
    trigger="click"
    placement="bottomRight"
    overlay-class-name="headerUserMenu"
  >
    <a slot="trigger" href="javascript:;">
      <FlotoUserAvatar
        size="large"
        class="rounded-full cursor-pointer"
        :avatar="user.avatar"
      />
    </a>
    <MRow :gutter="0">
      <MCol class="w-64 items-center justify-around">
        <MRow>
          <MCol align="center" justify="middle">
            <FlotoUserAvatar
              :size="80"
              class="rounded-full cursor-pointer"
              :avatar="user.avatar"
            />
          </MCol>
        </MRow>
        <MRow class="mt-4">
          <MCol>
            <div class="text-center">
              <h2 class="mb-0">{{ user.name }}</h2>
            </div>
            <div class="text-center">
              <small>{{ user.email || user.userLogOnName }}</small>
            </div>
          </MCol>
        </MRow>
        <MRow class="mt-4 justify-around" :gutter="0">
          <UserProfile class="mb-1" @trigger="hide" />
          <MButton
            id="signout-btn"
            variant="error"
            class="mb-1"
            @click="performLogout"
          >
            {{ $t('logout') }}
          </MButton>
        </MRow>
        <MRow v-if="user.userType === 'technician'" class="mt-4">
          <MCol class="text-center">
            <a @click="handlePortalChange">
              <div class="font-semibold">
                {{ $t('view') }}
                {{
                  isInsidePortal ? technicianPortalTitle : customerPortalTitle
                }}
              </div>
            </a>
          </MCol>
        </MRow>
        <MRow class="mt-4">
          <MCol v-if="!isPortalLogin" class="text-center">
            <FlotoLink :to="$modules.getModuleRoute('product-setup')">
              <div class="font-semibold" @click="hide">
                {{ $tc('product_guide') }}
              </div>
            </FlotoLink>
          </MCol>
        </MRow>
      </MCol>
    </MRow>
  </MPopover>
</template>

<script>
import Bus from '@utils/emitter'
import { authMethods, authComputed } from '@state/modules/auth/'
import UserProfile from './user-profile'
export default {
  name: 'UserDropdown',
  components: { UserProfile },
  props: {
    portalMode: { type: Boolean, default: false },
    customerPortalTitle: { type: String, default: 'support_portal' },
    technicianPortalTitle: { type: String, default: 'technician_portal' },
  },
  computed: {
    ...authComputed,
    isInsidePortal() {
      return this.isPortalLogin
      // return this.$route.path.indexOf('support-portal') >= 0
    },
  },
  methods: {
    ...authMethods,
    hide() {
      this.$refs.popoverMenu.hide()
    },
    performLogout() {
      const currentUser = this.user
      this.logout()
      Bus.$emit('auth:logout', currentUser)
      this.$modules.replace('support-portal')
    },
    handlePortalChange() {
      this.$router.push(
        this.$modules.getModuleRoute(
          this.isInsidePortal ? 'dashboard' : 'support-portal'
        )
      )
    },
  },
}
</script>

<style lang="less">
.headerUserMenu {
  .@{ant-prefix}-popover-content {
    margin-top: 14px;
  }
  .@{ant-prefix}-popover-arrow {
    top: 20px !important;
  }
  .@{ant-prefix}-popover-arrow,
  .@{ant-prefix}-tooltip-arrow {
    display: none;
  }
  & .@{ant-prefix}-popover-inner {
    @apply border-solid border-primary border-l-0 border-r-0 border-t-0 rounded-lg;

    background-color: var(--user-dropdown-button-bg);
    border-bottom-width: 5px;
  }
}
</style>
