import api from '@api'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const transformDelegateApproval = (data) => {
  if (data.enabled) {
    return {
      id: data.id,
      enabled: data.enabled,
      userId: data.userId,
      startTime: data.startTime,
      endTime: data.endTime,
      comment: data.comment,
      createdBy: data.createdById,
    }
  } else {
    return {}
  }
}

const transformDelegateApprovalForServer = (data) => {
  if (data.enabled) {
    return {
      enabled: data.enabled,
      userId: data.userId,
      startTime: data.startTime,
      endTime: data.endTime,
      comment: data.comment,
    }
  }
  return {
    enabled: data.enabled,
  }
}

export function getDelegateApprovalApi() {
  return api.get('/user/delegateapproval').then(transformDelegateApproval)
}

export function updateDelegateApprovalApi(data) {
  return api
    .patch('/user/delegateapproval', transformDelegateApprovalForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc(`${__rootTc('delegate')} ${__rootTc('approval')}`),
      }),
    })
    .then(transformDelegateApproval)
}
