<template>
  <FlotoDrawerForm
    :open="isDrawerOpen"
    @cancel="isDrawerOpen = false"
    @submit="handleFormSubmit"
  >
    <template v-slot:trigger>
      <MButton id="edit-profile" variant="neutral" @click="handleOpenProfile">
        {{ $t('edit_profile') }}
      </MButton>
    </template>
    <template v-slot:header>
      {{ user.name || user.email }}
    </template>
    <div class="flex flex-col flex-1">
      <FlotoFixedView :gutter="0">
        <div class="h-100 flex flex-col">
          <div class="flex flex-col">
            <div class="flex items-center">
              <div>
                <FlotoUserAvatar :size="70" :avatar="user.avatar" />
              </div>
              <div class="mx-4">
                <h3 class="mb-0">
                  {{ user.name }}
                </h3>
                <small v-if="user.email">
                  <a :href="`mailto:${user.email}`">
                    {{ user.email }}
                  </a>
                </small>
                <small v-else>
                  {{ user.userLogOnName }}
                </small>
                <MTag
                  v-if="user.vip"
                  size="large"
                  variant="orange"
                  :closable="false"
                  class="ml-2"
                >
                  {{ $t('vip') }}
                </MTag>
              </div>
            </div>
            <MTab v-model="currentTab">
              <MTabPane key="profile" :tab="$t('profile')" />
              <MTabPane key="preference" :tab="$tc('preference', 2)" />
              <MTabPane
                v-if="!user.ldapUser && user.loginSource !== 'sso_login'"
                key="security"
                :tab="$tc('change_password')"
              />
              <MTabPane
                v-if="isLicenseAvailable"
                key="delegate_approval"
                :tab="`${$tc('delegate')} ${$tc('approval')}`"
              />
            </MTab>
          </div>
          <div class="h-100 overflow-hidden">
            <FlotoScrollView>
              <div class="flex flex-col flex-wrap">
                <ProfileForm
                  v-if="currentTab === 'profile'"
                  v-model="userFormData"
                  :show-security="
                    Boolean(
                      user.ldapUser && tenantPrefrences.selfServiceSetting.value
                    )
                  "
                />

                <PreferenceForm
                  v-if="currentTab === 'preference'"
                  v-model="preferenceFormData"
                />
                <ChangePasswordForm
                  v-if="currentTab === 'security'"
                  v-model="securityFormData"
                />
                <DelegateApprovalForm
                  v-if="currentTab === 'delegate_approval'"
                  v-model="delegateApprovalFormData"
                />
              </div>
            </FlotoScrollView>
          </div>
        </div>
      </FlotoFixedView>
    </div>
    <template v-slot:actions="{ hide, submit }">
      <MButton
        id="update-btn"
        class="mx-1"
        :loading="processing"
        @click="submit"
      >
        {{ buttonText }}
      </MButton>
      <MButton id="cancel-btn" variant="default" @click="hide">{{
        $t('cancel')
      }}</MButton>
    </template>
  </FlotoDrawerForm>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import { authComputed, authMethods } from '@state/modules/auth'
import { updateUserApi } from '@modules/users/users-api'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'
import { LicenseComputed } from '@state/modules/license'
import ProfileForm from './profile/profile-form'
import PreferenceForm from './profile/preference-form'
import ChangePasswordForm from './profile/change-password-form.vue'
import { changePasswordApi } from '@state/modules/auth/api'
import DelegateApprovalForm from './profile/delegate-approval-form'
import { updateDelegateApprovalApi } from './profile/api'

export default {
  name: 'UserProfile',
  components: {
    ProfileForm,
    PreferenceForm,
    ChangePasswordForm,
    DelegateApprovalForm,
  },
  data() {
    return {
      isDrawerOpen: false,
      userFormData: {},
      preferenceFormData: {},
      securityFormData: {},
      delegateApprovalFormData: {},
      currentTab: 'profile',
      processing: false,
    }
  },
  computed: {
    ...authComputed,
    ...PreferenceComputed,
    ...LicenseComputed,
    buttonText() {
      return this.$t('update')
    },
    isLicenseAvailable() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0 ||
        this.availableModulesInLicense.indexOf(
          this.$constants.SERVICE_CATALOG
        ) >= 0 ||
        this.availableModulesInLicense.indexOf(this.$constants.PROBLEM) >= 0 ||
        this.availableModulesInLicense.indexOf(this.$constants.CHANGE) >= 0 ||
        this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0 ||
        this.availableModulesInLicense.indexOf(this.$constants.RELEASE) >= 0 ||
        this.availableModulesInLicense.indexOf(this.$constants.PURCHASE) >= 0
      )
    },
  },
  methods: {
    ...authMethods,
    ...PreferenceMethods,
    fillFormData() {
      this.userFormData = CloneDeep(this.user)
      this.securityFormData = {}
      this.delegateApprovalFormData = {}
      this.preferenceFormData = {
        pageSize: this.pageSize(),
        layout: this.defaultLayout(this.$constants.REQUEST),
        theme: this.theme,
      }
    },
    handleOpenProfile() {
      this.fillFormData()
      this.$emit('trigger')
      setTimeout(() => (this.isDrawerOpen = true), 250)
    },
    handleFormSubmit() {
      if (this.currentTab === 'profile') {
        this.processing = true
        updateUserApi(
          this.userFormData.userType,
          this.userFormData,
          this.$t('updated_successfully', { resource: this.$t('profile') })
        )
          .then((data) => {
            if (this.user.userLanguage !== data.userLanguage) {
              this.isDrawerOpen = false
              // refresh user for change language
              this.refreshUser({ pageReload: true })
            }
          })
          .finally(() => {
            this.processing = false
          })
      }
      if (this.currentTab === 'preference') {
        this.processing = true
        this.updatePreference({
          pageSize: this.preferenceFormData.pageSize,
          theme: this.preferenceFormData.theme,
          modulePreferences: {
            [this.$constants.REQUEST]: {
              defaultLayout: this.preferenceFormData.layout,
            },
          },
        })
          .then(() => {
            this.isDrawerOpen = false
            this.processing = false
          })
          .finally(() => (this.processing = false))
        // updateUserApi()
      }
      if (this.currentTab === 'security') {
        this.processing = true
        changePasswordApi(this.securityFormData).finally(
          () => (this.processing = false)
        )
      }
      if (this.currentTab === 'delegate_approval') {
        this.processing = true
        updateDelegateApprovalApi(this.delegateApprovalFormData)
          .then((data) => {
            this.delegateApprovalFormData = data
          })
          .finally((this.processing = false))
      }
    },
  },
}
</script>
