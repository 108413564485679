var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-kanban-item w-full",class:{ 'cursor-pointer': !_vm.item.archived },style:(_vm.color
      ? {
          borderLeft: ("3px solid " + _vm.color),
        }
      : {}),attrs:{"id":"kanban-item"},on:{"click":_vm.handleEdit}},[_c('div',{staticClass:"flex"},[_c('h6',{staticClass:"text-primary text-ellipsis flex-1"},[_c('ResourceListTitle',{attrs:{"name":_vm.item.name,"subject":_vm.item.subject,"external-link":"","internal-link":false,"link":!_vm.item.archived
            ? _vm.getDetailLinkForModule(
                _vm.moduleName,
                _vm.item.id,
                _vm.$modules,
                _vm.moduleName
              ).link
            : undefined}})],1)]),_c('div',{staticClass:"flex flex-1 items-center justify-between"},[_c('div',{staticClass:"flex flex-1"},[_c('FlotoPriorityPicker',{staticClass:"mx-1",attrs:{"disabled":"","placeholder":"---","value":_vm.item.priorityId},scopedSlots:_vm._u([{key:"trigger",fn:function(slotData){return [_c('FlotoDropdownTrigger',_vm._b({attrs:{"compact-view":"","lable":_vm.$t('priority'),"icon-name":"priority","disabled":""},on:{"color":function ($event) { return (_vm.color = $event); }}},'FlotoDropdownTrigger',slotData,false))]}}])}),_c('FlotoDueDatePicker',{staticClass:"mx-1",attrs:{"compact-view":"","value":_vm.moduleName === _vm.$constants.TASK
            ? (_vm.item.timeRange || {}).endTime || 0
            : _vm.item.dueBy,"disabled":"","module-name":_vm.moduleName,"status-id":_vm.item.statusId,"icon-size":"lg"},scopedSlots:_vm._u([{key:"after-text",fn:function(){return [_c('div',{staticClass:"w-full text-ellipsis"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.dueByComputed))+" ")])]},proxy:true}])})],1),_c('div',{staticClass:"flex flex-1 justify-end items-center"},[(_vm.$constants.TASK && _vm.item.parentId)?_c('div',{staticClass:"mx-1"},[_c('FlotoLink',{attrs:{"id":"open-new-tab-link","target":"_blank","to":_vm.getDetailLinkForModule(
              _vm.item.moduleName,
              _vm.item.parentId,
              _vm.$modules,
              _vm.item.moduleName
            ).link}},[_vm._v(" "+_vm._s(_vm.item.reference)+" ")])],1):_vm._e(),_c('FlotoTechnicianPicker',{attrs:{"size":35,"value":_vm.item.technicianId,"disabled":"","group-id":_vm.item.groupId},scopedSlots:_vm._u([{key:"trigger",fn:function(slotData){return [_c('FlotoDropdownTrigger',_vm._b({attrs:{"disabled":"","compact-view":"","lable":_vm.$tc('assignee'),"icon-name":"vector"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlotoUserAvatar',{style:({
                  minWidth: ((24) + "px"),
                }),attrs:{"size":25,"avatar":(slotData.currentItem || {}).avatar}})]},proxy:true}],null,true)},'FlotoDropdownTrigger',slotData,false))]}}])})],1)]),(_vm.isEditing)?_c('div',{staticClass:"editing-item"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }