<template>
  <div
    class="flex flex-1 items-center h-full hierarchy-item relative cursor-pointer min-w-0"
    :style="style"
    :class="{
      'cursor-not-allowed':
        (onlyLeafNodeSelectable && (node.children || []).length) ||
        node.disabled,
      active: selectedNodesId.indexOf(node.id) >= 0,
    }"
    v-on="$listeners"
  >
    <MarkedContent ref="markedRef" :key="node.id" :text="highlightTerm || ''">
      <div
        :id="node.name"
        class="title-container flex flex-1 items-center min-w-0 h-full"
        :class="{ 'with-bg': withBg }"
      >
        <slot />
      </div>
    </MarkedContent>
    <!-- <span
      v-if="node.state.depth > 0"
      class="vertical-line"
      :style="{
        left: `0`,
        top: '-15px',
        bottom: '50%',
      }"
    />
    <span
      v-if="node.state.depth > 0"
      class="horizontal-line"
      :style="{
        width: `${marginLevel}px`,
        left: 0,
      }"
    /> -->
  </div>
</template>

<script>
import MarkedContent from '@modules/admin/components/marked-content'
export default {
  name: 'TreeNode',
  components: { MarkedContent },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    selectedNodesId: {
      type: Array,
      default() {
        return []
      },
    },
    node: {
      type: Object,
      required: true,
    },
    tree: {
      type: Object,
      required: true,
    },
    marginLevel: {
      type: Number,
      default: 20,
    },
    withBg: {
      type: Boolean,
      // eslint-disable-next-line
      default: true,
    },
    onlyLeafNodeSelectable: { type: Boolean, default: false },
    highlightTerm: { type: String, default: '' },
  },
  computed: {
    style() {
      return {
        marginLeft: `${this.depth * this.marginLevel}px`,
      }
    },
    depth() {
      return this.node.state.depth
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.markedRef.markText()
    }, 300)
  },
}
</script>

<style lang="less" scoped>
.horizontal-line,
.vertical-line {
  position: absolute;
  background: var(--border-color);
}

.horizontal-line {
  height: 1px;
}

.vertical-line {
  width: 1px;
}
</style>
