<template>
  <FlotoCrudContainer
    ref="list"
    as-table
    :scrollable="searchable || scrollable"
    :module-name="moduleName"
    :immediate="!searchable"
    :fetch-fn="fetchTicketFn"
    :columns="tableColumns"
    :selectable="selectable"
    v-bind="$attrs"
    @selection-change="$emit('selection-change', $event)"
  >
    <template
      v-if="searchable"
      v-slot:add-controls="{
        refreshList,
      }"
    >
      <MRow class="items-center">
        <MCol>
          <h2 class="font-normal inline-block mb-0">
            {{ $tc(moduleName, 2) }}
          </h2>
          <FlotoSearchSelector
            v-model="currentSelectedSearch"
            exclude-archived-spam-qual
            :allow-pin="false"
            :persist-search="false"
            :module-name="moduleName"
          />
        </MCol>
      </MRow>
      <MRow class="my-4">
        <MCol
          :class="{
            invisible:
              ((currentSelectedSearch || {}).systemName || '').indexOf(
                'archive'
              ) >= 0,
          }"
        >
          <FlotoSearchBar
            v-model="searchCriteria"
            :default-value="
              currentSelectedSearch ? currentSelectedSearch.qualifications : []
            "
            :should-notify-on-default-change="true"
            :allow-save-search="false"
            :persist-criteria="false"
            :module-name="moduleName"
            @change="refreshList"
          />
        </MCol>
      </MRow>
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:subject="{ item }">
      <td>
        <ResourceListTitle
          :name="item.name"
          :subject="item.displayName"
          :user-id="item.requesterId"
          :created-at="item.createdAt"
          open-in-new-tab
          :link="
            $modules.getModuleRoute('purchase', 'view', {
              params: { id: item.id },
            })
          "
        />
      </td>
    </template>
    <template v-slot:status="{ item }">
      <td>
        <FlotoStatusPicker
          :module-name="moduleName"
          placeholder="---"
          :value="item.statusId"
          disabled
        />
      </td>
    </template>
    <template v-slot:vendorId="{ item }">
      <td class="text-ellipsis">
        {{ vendorName(item) }}
      </td>
    </template>
    <template v-slot:requiredByTime="{ item }">
      <td class="text-ellipsis">
        {{ item.requiredByTime | datetime(undefined, false) }}
      </td>
    </template>
    <template v-slot:actions="{ item }">
      <td
        :class="{
          'sticky-action': actionSticked.indexOf(item.id) >= 0,
          'hover-action-container': hoverableActions,
        }"
      >
        <slot
          name="action"
          :item="item"
          :stickAction="() => stickActionFor(item)"
          :unstickAction="() => unStickActionFor(item)"
        />
      </td>
    </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import Uniq from 'lodash/uniq'
import {
  transformSearchCriteriasForServer,
  transformSearchCriteriaForServer,
} from '@data/search'
import { searchPurchasesApi } from '@modules/purchase/purchase-api'
import ResourceListTitle from '@components/resource/resource-list-title'
import { getVendorCatalogsApi } from '@modules/asset-management/api/vendor-catalog-api'

export default {
  name: 'PurchaseSelectionList',
  components: { ResourceListTitle },
  props: {
    moduleName: {
      type: String,
      default() {
        return 'purchase'
      },
    },
    fetchFn: { type: Function, default: undefined },
    hasActions: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    hiddenColumns: {
      type: Array,
      default() {
        return []
      },
    },
    excludeItemsById: {
      type: Array,
      default() {
        return []
      },
    },
    hoverableActions: { type: Boolean, default: false },
  },
  data() {
    this.tableColumns = [
      {
        name: `${this.$tc('name')}`,
        key: 'subject',
        widthClass: 'xl:w-6/12 lg:w-5/12 md:w-4/12',
      },
      {
        name: `${this.$tc('order')} ${this.$tc('number')}`,
        key: 'orderNumber',
      },
      { name: `${this.$tc('status')}`, key: 'status', width: '160px' },
      { name: `${this.$tc('vendor')}`, key: 'vendorId' },
      { name: `${this.$tc('required_by')}`, key: 'requiredByTime' },

      ...(this.hasActions
        ? [
            {
              name: this.$tc('action', 2),
              key: 'actions',
              width: '80px',
            },
          ]
        : []),
    ]
    return {
      searchCriteria: transformSearchCriteriasForServer(this.moduleName, []),
      actionSticked: [],
      vendorList: [],
      currentSelectedSearch: null,
    }
  },
  methods: {
    fetchTicketFn(limit, offset) {
      const criterias = CloneDeep(this.searchCriteria)
      if (this.excludeItemsById.length) {
        const q = transformSearchCriteriaForServer(this.moduleName, {
          operator: { value: 'not_in' },
          paramName: 'id',
          value: this.excludeItemsById,
          valueType: 'long',
        })
        criterias.quals.push(q)
      }
      if (this.fetchFn) {
        return this.fetchFn(this.moduleName, criterias, limit, offset).then(
          (response) => {
            const vendorIds = response.items.map((i) => i.vendorId)
            if (vendorIds.length) {
              this.getVendors(vendorIds)
            }
            return response
          }
        )
      } else {
        return searchPurchasesApi(criterias, limit, offset).then((response) => {
          const vendorIds = response.items.map((i) => i.vendorId)
          if (vendorIds.length) {
            this.getVendors(vendorIds)
          }
          return response
        })
      }
    },
    getVendors(ids) {
      getVendorCatalogsApi({ ids }).then((data) => {
        this.vendorList = data.items
      })
    },
    vendorName(item) {
      if (item.vendorId) {
        const vendor = this.vendorList.find((v) => v.id === item.vendorId)
        return vendor ? `${vendor.name}: ${vendor.displayName}` : '---'
      }
      return '---'
    },
    refresh() {
      this.$refs.list.refresh()
    },
    stickActionFor(item) {
      this.actionSticked = Uniq([...this.actionSticked, item.id])
    },
    unStickActionFor(item) {
      this.actionSticked = this.actionSticked.filter((id) => id !== item.id)
    },
  },
}
</script>
