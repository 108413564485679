<script>
import Uniq from 'lodash/uniq'
import FindIndex from 'lodash/findIndex'
import CloneDeep from 'lodash/cloneDeep'
import {
  GuidedTourComputed,
  GuidedTourMethods,
} from '@state/modules/guided-tour'

export default {
  name: 'SetupGuideProvider',
  provide() {
    const setupGuideContext = {
      moveToNextSection: this.moveToNextSection,
      moveToNextStep: this.moveToNextStep,
      goToSection: this.goToSection,
      goToStep: this.goToStep,
      skipGuidePermanently: this.skipGuidePermanently,
      completeGuide: this.completeGuide,
      init: this.init,
    }
    Object.defineProperty(setupGuideContext, 'sections', {
      enumerable: true,
      get: () => {
        return this.sections
      },
    })
    Object.defineProperty(setupGuideContext, 'currentSection', {
      enumerable: true,
      get: () => {
        return this.currentSection
      },
    })
    Object.defineProperty(setupGuideContext, 'currentStep', {
      enumerable: true,
      get: () => {
        return this.currentStep
      },
    })
    Object.defineProperty(setupGuideContext, 'guideType', {
      enumerable: true,
      get: () => {
        return this.guideType
      },
    })
    Object.defineProperty(setupGuideContext, 'isCurrentGuideCompleted', {
      enumerable: true,
      get: () => {
        return this.isCurrentGuideCompleted
      },
    })
    return { setupGuideContext }
  },
  data() {
    return {
      sections: [],
      guideType: undefined,
      currentSectionId: undefined,
      currentStepId: undefined,
      isCurrentGuideCompleted: false,
    }
  },
  computed: {
    ...GuidedTourComputed,
    currentSection() {
      const index = FindIndex(this.sections, { id: this.currentSectionId })
      return this.sections[index]
    },
    currentStep() {
      if (this.currentStepId && this.currentSection) {
        const steps = this.currentSection.steps

        const index = FindIndex(steps, { id: this.currentStepId })
        if (index >= 0) {
          return steps[index]
        }
      }
      return undefined
    },
  },
  created() {
    this.fetchGuidedTour()
  },
  methods: {
    ...GuidedTourMethods,
    handleUpdatePartialGuidedTour(data) {
      return this.updateGuidedTour(data)
    },
    init(guideType, sections) {
      this.guideType = guideType
      this.sections = Object.freeze(CloneDeep(sections))
      const completedSections = this.setupCompletedSections
      const nextPossibleSection = sections.find(
        (s) => completedSections.includes(s.id) === false
      )
      if (nextPossibleSection) {
        this.isCurrentGuideCompleted = false
        this.currentSectionId = nextPossibleSection.id
        const completedSteps = this.setupCompletedSteps
        const nextPossibleStep = nextPossibleSection.steps.find(
          (step) => completedSteps.includes(step.id) === false
        )
        if (nextPossibleStep) {
          this.currentStepId = nextPossibleStep.id
        } else {
          this.moveToNextSection()
        }
      } else {
        this.isCurrentGuideCompleted = true
        this.currentSectionId = this.sections[0].id
        this.currentStepId = this.sections[0].steps[0].id
      }
    },
    goToSection(id) {
      const index = FindIndex(this.sections, { id })
      const section = this.sections[index]
      if (section) {
        this.currentSectionId = section.id
        this.currentStepId = section.steps[0] ? section.steps[0].id : undefined
      }
    },
    goToStep(id) {
      this.currentStepId = id
    },
    moveToNextSection(allowCompleteGuide = true) {
      const index = FindIndex(this.sections, { id: this.currentSectionId })
      const nextSection = this.sections[index + 1]
      if (nextSection) {
        this.isCurrentGuideCompleted = !!this.setupCompletedSections.includes(
          nextSection.id
        )
        this.currentSectionId = nextSection.id
        this.currentStepId = nextSection.steps[0]
          ? nextSection.steps[0].id
          : undefined
        return this.handleUpdatePartialGuidedTour({
          setupCompletedSteps: Uniq([
            ...this.setupCompletedSteps,
            ...this.sections[index].steps.map((i) => i.id),
          ]),
          setupCompletedSections: Uniq([
            ...(this.setupCompletedSections || []),
            this.sections[index].id,
          ]),
        })
      } else {
        if (allowCompleteGuide) {
          return this.completeGuide()
        } else {
          return Promise.resolve()
        }
      }
    },
    moveToNextStep() {
      const index = FindIndex(this.sections, { id: this.currentSectionId })
      const currentSection = this.sections[index]
      if (currentSection) {
        const stepIndex = FindIndex(currentSection.steps, {
          id: this.currentStepId,
        })
        const nextStep = currentSection.steps[stepIndex + 1]
        if (nextStep) {
          this.currentStepId = nextStep.id
          return this.handleUpdatePartialGuidedTour({
            setupCompletedSteps: Uniq([
              ...(this.setupCompletedSteps || []),
              currentSection.steps[stepIndex].id,
            ]),
          })
        } else {
          return this.moveToNextSection()
        }
      }
    },
    completeGuide() {
      return this.handleUpdatePartialGuidedTour({
        setupCompletedGuides: Uniq(
          [...(this.setupCompletedGuides || []), this.guideType].filter(Boolean)
        ),
        setupCompletedSections: Uniq(
          [
            ...(this.setupCompletedSections || []),
            ...this.sections.map(({ id }) => id),
          ].filter(Boolean)
        ),
        setupCompletedSteps: Uniq(
          [
            ...(this.setupCompletedSteps || []),
            ...this.sections.reduce(
              (prev, { steps }) => [...prev, ...steps.map(({ id }) => id)],
              []
            ),
          ].filter(Boolean)
        ),
      }).then(() => {
        this.isCurrentGuideCompleted = true
        this.sections = []
        this.currentSectionId = undefined
        this.currentStepId = undefined
        this.$router.replace(this.$modules.getModuleRoute('product-setup'))
      })
    },
    skipGuidePermanently() {
      return this.handleUpdatePartialGuidedTour({
        setupCompleted: true,
      }).then(() => {
        this.sections = []
        this.currentSectionId = undefined
        this.currentStepId = undefined
        this.$router.replace(this.$modules.getModuleRoute('dashboard'))
      })
    },
  },
  render() {
    return this.$scopedSlots.default()
  },
}
</script>
