<template>
  <MRow :gutter="0">
    <MCol :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="enabled-switch"
        :label="`${$tc('delegate')} ${$tc('approval', 2)}`"
      >
        <MSwitch v-model="data.enabled" size="small" />
      </FlotoFormItem>
      <FlotoFormItem
        v-if="data.enabled"
        id="delegation-start-date"
        :label="`${$tc('delegation')} ${$t('start')} ${$t('date')}`"
        rules="required|nonzero"
      >
        <FlotoDatePicker
          v-model="data.startTime"
          :disabled="data.id > 0 && data.startTime > 0 ? true : false"
          :max-date="data.endTime"
          :min-date="Moment().valueOf()"
          :show-time="false"
        />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="data.enabled" :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="delegate-to"
        :label="`${$tc('delegate')} ${$tc('to')}`"
        rules="required"
      >
        <FlotoRequesterPicker
          v-model="data.userId"
          :disabled="data.id > 0 && data.userId > 0 ? true : false"
          as-input
        />
      </FlotoFormItem>
      <FlotoFormItem
        id="delegation-end-date"
        :label="`${$tc('delegation')} ${$t('end')} ${$t('date')}`"
      >
        <FlotoDatePicker
          v-model="data.endTime"
          :min-date="data.startTime"
          :show-time="false"
          end-of-day
        />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="data.enabled" :size="12" :sm="12" class="px-2">
      <FlotoFormItem
        id="comments"
        v-model="data.comment"
        :label="`${$tc('comment', 2)}`"
        type="textarea"
        :rows="3"
      />
    </MCol>
  </MRow>
</template>

<script>
import Moment from 'moment'
import { getDelegateApprovalApi } from './api'

export default {
  name: 'DelegateApprovalForm',
  model: { event: 'change' },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    this.Moment = Moment
    return {}
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
  created() {
    getDelegateApprovalApi().then((data) => {
      this.data = data
    })
  },
}
</script>
