<template>
  <MRow :gutter="0">
    <MCol :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="name"
        v-model="data.name"
        :label="$t('name')"
        rules="required"
      />
    </MCol>
    <MCol :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="contact-no"
        v-model="data.contactNo"
        :label="$t('contact_no')"
        :placeholder="$t('contact_no')"
        rules="contact_number"
      />
    </MCol>
    <template v-if="!isPortalLogin">
      <MCol :size="12" :sm="6" class="px-2">
        <FlotoFormItem id="location-picker" :label="$tc('location')">
          <FlotoLocationPicker
            v-model="data.location"
            :placeholder="$tc('location')"
            class="w-full"
          />
        </FlotoFormItem>
      </MCol>
      <MCol :size="12" :sm="6" class="px-2">
        <FlotoFormItem id="department-picker" :label="$tc('department')">
          <FlotoDepartmentPicker v-model="data.department" as-input />
        </FlotoFormItem>
      </MCol>
    </template>
    <MCol :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="timezone-picker"
        rules="required"
        :label="$t('timezone')"
      >
        <FlotoTimeZonePicker v-model="data.timezone" class="w-full" />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="!isPortalLogin" :size="12" :sm="6" class="px-2">
      <FlotoFormItem id="do-not-disturb" :label="$t('do_not_disturb')">
        <MSwitch
          v-model="data.doNotDisturb"
          :disabled="!formData.allowTechnicianToChangeDoNotDisturb"
          size="small"
        />
      </FlotoFormItem>
    </MCol>
    <MCol :size="6" class="px-2">
      <FlotoFormItem id="language-selector" :label="$t('language_preference')">
        <FlotoLanguageSelector v-model="data.userLanguage" />
      </FlotoFormItem>
    </MCol>
    <MDivider v-if="showSecurity" />
    <SecurityQuestionAnswerList v-if="showSecurity" class="px-2" />
  </MRow>
</template>

<script>
import { getTenantPrefrencesApi } from '@modules/organization/tenant-prefrences-api'
import SecurityQuestionAnswerList from './security-question-answer-list'
import { authComputed } from '@state/modules/auth/'
export default {
  name: 'ProfileForm',
  components: { SecurityQuestionAnswerList },
  model: { event: 'change' },
  props: {
    value: { type: Object, required: true },
    showSecurity: { type: Boolean, default: false },
  },
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    ...authComputed,
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
  created() {
    getTenantPrefrencesApi().then((data) => {
      this.formData = { ...data }
    })
  },
}
</script>
