var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 flex-col min-w-0 dropdown-multiple-trigger",class:{ 'cursor-pointer': !_vm.disabled },on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)},"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseExit}},[(_vm.disabled)?[((_vm.selectedItems || []).length)?_c('div',{class:[
        {
          'flex items-center min-w-0 px-1 rounded border border-solid border-neutral-lighter trigger':
            !_vm.asTag,
        },
        _vm.inputClasses ]},[_c('SelectedItemPills',{attrs:{"value":(_vm.selectedItems || []).map(function (i) { return _vm.map[i] || i || _vm.map[0]; }),"closable":_vm.canRemoveSelectedItemsPill,"variant":!_vm.asTag
            ? 'dropdown-trigger'
            : _vm.canRemoveSelectedItemsPill
            ? 'default'
            : 'neutral-lighter',"use-popover":_vm.$attrs['use-popover'],"max-items":_vm.displayAllSelectedItemsPill
            ? (_vm.selectedItems || []).length
            : undefined},on:{"close":function (id) { return _vm.handleRemoveTag(id); }}})],1):_c('div',{class:[
        {
          'flex items-center min-w-0 px-1 rounded border border-solid border-neutral-lighter trigger':
            !_vm.asTag,
        },
        _vm.inputClasses ]},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])]:_c('div',{staticClass:"flex items-center min-w-0 px-1 rounded border border-solid border-neutral-lighter trigger",class:[{ 'bg-neutral-lightest': _vm.disabled }, _vm.inputClasses]},[((_vm.selectedItems || []).length)?_c('SelectedItemPills',{attrs:{"value":(_vm.selectedItems || []).map(function (i) { return _vm.map[i] || i || _vm.map[0]; }),"use-popover":_vm.$attrs['use-popover'],"variant":"dropdown-trigger","closable":!_vm.disabled,"disabled":!_vm.disabled},on:{"close":function (id) { return _vm.handleRemoveTag(id); }}}):_c('div',{staticClass:"text-neutral-light flex-1 label-list pl-2",staticStyle:{"height":"29px","line-height":"27px"}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),(_vm.isActiveHover && _vm.allowClear && !_vm.disabled)?_c('MIcon',{staticClass:"cursor-pointer text-neutral-light mx-1",attrs:{"type":"fas","name":"times-circle","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('change', [])}}}):_vm._e(),(!_vm.isActiveHover && !_vm.disabled)?_c('MIcon',{staticClass:"cursor-pointer text-neutral-light mx-1",attrs:{"type":"fal","name":"chevron-down","size":"sm"}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }