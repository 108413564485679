<template>
  <MTag rounded variant="neutral-lighter" @close="handleRemove">
    <template v-slot:confirm-title>
      {{ $t('confirm_remove_item', { item: source.name || value.value }) }}
    </template>
    <div class="tag-content mx-1">
      <template v-if="source.valueType === 'keyword'">
        <span :title="value.value">{{ value.value }}</span>
      </template>
      <template v-else-if="!source.name">
        <span :title="$tc(value.paramName)">{{ $tc(value.paramName) }}</span>
      </template>
      <MPopover
        v-else
        ref="popover"
        :placement="popoverPlacment"
        transition-name="slide-up"
        overlay-class-name="picker-overlay"
        :default-open="!hasValue"
        destroy-tooltip-on-hide
        @hide="cancelSelection"
      >
        <template v-slot:trigger>
          <span :title="tagTitle">
            {{ source.name }} {{ value.operator.name }} {{ selectedValue }}
            <template v-if="(value.operator || {}).value === 'between'">
              &nbsp; {{ $t('to') }} &nbsp;
              {{ selectedToValue }}
            </template>
          </span>
        </template>
        <ValueInput
          :type="source.inputType"
          :value-type="source.valueType"
          :placeholder="source.name"
          :param-name="source.paramName"
          :options="(sourceOptions || []).filter((o) => o.archived !== true)"
          :custom-field-type="source.customFieldType"
          :additional-options="additionalOption"
          :operator="value.operator"
          :allow-decimal="source.allowDecimal"
          :value="value.value"
          :to-value="value.toValue"
          :operators="source.operators"
          :module-name="moduleName"
          :available-asset-type="availableAssetType"
          @selected="handleValueSelected"
        />
      </MPopover>
    </div>
  </MTag>
</template>

<script>
import { unaryOperators } from '@data/operator'
import { authComputed } from '@state/modules/auth'
import { flattenRecursive } from '@data/recursive'
import ValueInput from './value-input'

export default {
  name: 'ValueSelector',
  components: { ValueInput },
  inject: ['searchBarContext'],
  model: {
    event: 'change',
  },
  props: {
    source: { type: Object, required: true },
    moduleName: { type: String, required: true },
    value: {
      type: Object,
      default() {
        return {
          operator: {},
        }
      },
    },
    availableAssetType: { type: [String, Array], default: undefined },
  },
  computed: {
    ...authComputed,
    popoverPlacment() {
      return this.isRtl ? 'bottomRight' : 'bottomLeft'
    },
    tagTitle() {
      if ((this.value.operator || {}).value === 'between') {
        return `${this.source.name} ${this.value.operator.name} ${
          this.selectedValue
        } ${this.$t('to')} ${this.selectedToValue}`
      }
      return `${this.source.name} ${this.value.operator.name} ${this.selectedValue}`
    },
    hasValue() {
      const operatorValue = (this.value.operator || {}).value
      if (operatorValue && unaryOperators.indexOf(operatorValue) >= 0) {
        return true
      }
      return this.value.value !== undefined
      // return Boolean(this.value.value)
    },
    selectedToValue() {
      if (!this.value.toValue && this.value.toValue !== 0) {
        return undefined
      }
      return this.transformValue(this.value.toValue)
    },
    selectedValue() {
      if (typeof this.value.value === 'boolean') {
        return this.transformValue(this.value.value)
      }
      if (!this.value.value && this.value.value !== 0) {
        return undefined
      }
      return this.transformValue(this.value.value)
    },
    additionalOption() {
      let option = []
      if (
        ['location', 'category', 'dependent', 'department'].indexOf(
          this.source.inputType
        ) >= 0
      ) {
        option = [
          {
            id: 0,
            value: 0,
            label: this.$t('none'),
            name: this.$t('none'),
          },
        ]
      }
      if (['company'].indexOf(this.source.inputType) >= 0) {
        option = [
          {
            id: 0,
            value: 0,
            key: 0,
            text: this.$t('none'),
            label: this.$t('none'),
            name: this.$t('none'),
          },
        ]
      }
      return option
    },
    sourceOptions() {
      let options = this.source.options
      if (this.source.customFieldType === 'dropdown') {
        options = [{ text: this.$t('none'), key: '', id: '' }, ...options]
      }
      const archivedOpions =
        this.searchBarContext.archivedOptionsMap[this.source.vuexOptionKey]
      if (archivedOpions) {
        options = [...options, ...(archivedOpions || [])]
      }
      return options
    },
  },
  watch: {
    'value.value': {
      immediate: true,
      handler(newValue, prevValue) {
        if (newValue && newValue !== prevValue) {
          if (this.source.inputType === 'requester') {
            this.searchBarContext.getRequesters(newValue)
          }
          if (this.source.inputType === 'product') {
            this.searchBarContext.getProducts(newValue)
          }
          if (this.source.inputType === 'vendor') {
            this.searchBarContext.getVendors(newValue)
          }
          if (this.source.inputType === 'remoteOffice') {
            this.searchBarContext.getRemoteOffices(newValue)
          }
          if (this.source.inputType === 'company') {
            this.searchBarContext.getCompanies(newValue)
          }
          if (this.source.inputType === 'template') {
            this.searchBarContext.getTemplates(
              this.$constants.REQUEST,
              newValue
            )
          }
          if (this.source.inputType === 'deploymentPolicy') {
            this.searchBarContext.getDeploymentPolicy(newValue)
          }
          if (this.source.vuexOptionKey) {
            this.searchBarContext.getArchivedOptions(
              this.value.value,
              this.source.vuexOptionKey
            )
          }
        }
      },
    },
  },
  methods: {
    transformValue(value) {
      if (
        [
          'category',
          'location',
          'assetType',
          'dependent',
          'department',
        ].indexOf(this.source.inputType) >= 0
      ) {
        const flatArray = flattenRecursive(this.sourceOptions)
        return (this.additionalOption || [])
          .concat(flatArray)
          .filter((o) => this.value.value.indexOf(o.key || o.id) >= 0)
          .map((o) => o.text || o.label || o.name)
          .join(', ')
      }
      if (
        [
          'dropdown',
          'product',
          'vendor',
          'requester',
          'remoteOffice',
          'deploymentPolicy',
          'template',
          'company',
        ].indexOf(this.source.inputType) >= 0
      ) {
        if (this.source.valueType === 'boolean') {
          return this.sourceOptions
            .filter(
              (o) => `${this.value.value}`.indexOf(`${o.key}` || `${o.id}`) >= 0
            )
            .map((o) => o.text || o.label || o.name)
            .join(', ')
        }
        return (this.additionalOption || [])
          .concat(this.sourceOptions)
          .filter((o) => (this.value.value || []).indexOf(o.key || o.id) >= 0)
          .map((o) => o.text || o.label || o.name)
          .join(', ')
      }
      if (Array.isArray(value)) {
        return value.map((v) => v.text || v.label || v.name || v).join(', ')
      }
      if (['date', 'dateTime'].indexOf(this.source.inputType) >= 0) {
        if (['before', 'after'].includes(this.value.operator.value)) {
          return this.$options.filters.daytime(value)
        }
        return this.$options.filters.datetime(
          value,
          undefined,
          this.source.inputType === 'dateTime'
        )
      }
      if (['createdTime', 'dueBy'].indexOf(this.source.inputType) >= 0) {
        if (/^[a-z]+/.test(value)) {
          return this.$t(value)
        } else {
          if (['before', 'after'].includes(this.value.operator.value)) {
            return this.$options.filters.daytime(value)
          }
          return this.$options.filters.datetime(value, undefined, false)
        }
      }
      if (this.source.inputType === 'boolean') {
        return this.value.value === true ? this.$t('yes') : this.$t('no')
      }
      if (this.source.inputType === 'number' && this.source.allowDecimal) {
        return (value || 0).toFixed(2)
      }
      return value
    },
    handleRemove() {
      if (this.hasValue) {
        this.$emit('remove')
        return
      }
      this.$emit('cancel')
    },
    cancelSelection() {
      this.$emit('cancel')
    },
    handleValueSelected({ operator, value, toValue }) {
      this.$emit('change', {
        displayName: this.source.name,
        paramName: this.source.paramName,
        valueType: this.source.valueType,
        paramType: this.source.paramType,
        operator,
        value,
        toValue,
        // add left operand additional params for dependentField is true
        ...(this.source.inputType === 'dependent'
          ? { leftOperandAdditionalParams: { dependentField: true } }
          : {}),
      })
      if (this.hasValue) {
        // close popover after value is updated
        this.$nextTick(() => {
          this.$refs.popover.hide()
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.@{ant-prefix}-tag {
  .tag-content {
    max-width: 650px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
