<template>
  <div class="flex flex-col flex-1">
    <Component
      :is="stickySearch ? 'MAffix' : 'div'"
      v-if="addRelationTypes.length && !disabled"
      :offset-top="offsetTop"
      @change="searchAffixed = $event"
    >
      <div :class="{ 'pt-2': searchAffixed }" class="relation-header">
        <div class="m-align-button">
          <FlotoDropdownPicker
            id="createAndRelateDropdown"
            ref="createAndRelateDropdown"
            class="mx-2"
            :class="{ invisible: !(createRelationOptions.length && !disabled) }"
            :options="createRelationOptions"
            @change="createAndRelateNew"
          >
            <template v-slot:trigger="{ toggle }">
              <MButton variant="primary" @click="toggle">
                {{ $t('create_and_relate') }}
                <MIcon
                  class="mr-1"
                  name="chevron-down"
                  size="sm"
                  transform="shrink-2 right-4"
                />
              </MButton>
            </template>
          </FlotoDropdownPicker>
          <FlotoDropdownPicker
            id="addDropdown"
            ref="addDropdown"
            :class="{ invisible: !(addRelationTypes.length && !disabled) }"
            :options="addRelationOptions"
            @change="addNewRelation"
          >
            <template v-slot:trigger="{ toggle }">
              <MButton variant="primary" @click="toggle">
                {{ $tc('add') }} {{ $tc('relation') }}
                <MIcon
                  class="mr-2"
                  name="chevron-down"
                  size="sm"
                  transform="shrink-2 right-4"
                />
              </MButton>
            </template>
          </FlotoDropdownPicker>
          <MDivider />
        </div>
      </div>
    </Component>
    <MTab v-model="currentTab" position="left">
      <MTabPane
        v-for="tab in leftSideTabOptions"
        :key="tab.moduleName"
        :tab="`${tab.title} (${relationCount[tab.moduleName] || 0})`"
      >
        <RelationContent
          v-if="tab.moduleName === currentTab"
          :key="renderCount"
          :disabled="disabled"
          :source-module-name="subModuleName || moduleName"
          :relation-type="tab.moduleName === 'merged' ? 'child' : 'related'"
          :destination-module-name="
            tab.moduleName === 'merged' ? $constants.REQUEST : tab.moduleName
          "
          :source-id="resourceId"
          :title="tab.title"
          @related-ids-received="alreadyRelatedIds = $event"
          @related-items-received="alreadyRelatedItemsReceived"
          @relation-removed="relationRemoved"
        />
      </MTabPane>
    </MTab>
    <FlotoDrawer :open="showDrawer" width="65%" @hide="hideDrawer">
      <template v-slot:title>
        {{ $t('add') }} {{ $tc('relation', 2) }}
      </template>
      <component
        :is="selectionComponent"
        selectable
        :module-name="selectedRelationType"
        :source-module-name="subModuleName || moduleName"
        searchable
        :fetch-fn="fetchEligableRelations"
        @selection-change="setSelectedItems"
        @asset-sub-module-change="handleAssetSubModuleChange"
      />
      <template v-slot:actions="{ hide }">
        <MButton
          id="add-relation-btn"
          :disabled="selectedItems.length === 0"
          outline
          class="mx-1"
          :loading="processing"
          @click="handleAddRelations"
        >
          {{ $t('add') }} {{ $tc('relation') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
    <FlotoDrawerForm
      :open="showCreateDrawer"
      :width="
        selectedRelationType === $constants.SERVICE_CATALOG ? '90%' : '65%'
      "
      @cancel="hideDrawer"
      @submit="handleCreateAndRelate"
    >
      <template v-slot:header>
        {{ $t('create') }} {{ $tc(selectedRelationType) }}
      </template>
      <MRow v-if="allowCopyParentData">
        <MCol :size="12" class="mx-2 mb-4">
          <MCheckbox
            :checked="copyDataFromParent"
            @change="changeCopyDataFromParent"
          >
            {{ $tc('copy_data_from_parent_request') }}
          </MCheckbox>
        </MCol>
      </MRow>
      <FormRulesProvider
        :key="selectedRelationType"
        :module-name="selectedRelationType"
      >
        <component
          :is="formComponent"
          v-if="selectedRelationType"
          :key="renderCreateFormCount"
          :value="creatingResource"
          :module-name="selectedRelationType"
          :processing="processing"
          :use-template="!copyDataFromParent"
          :avoid-default-value="copyDataFromParent"
          :with-submit="false"
          :default-selected-assets="
            copyDataFromParent ? parentRequestSelectedAssets : []
          "
          :disabled="selectedRelationType === 'service_catalog'"
          @reset-form="handleResetForm"
          @templateSelected="handleTemplateSelected"
          @change="handleChangeCreatingResource"
        />
      </FormRulesProvider>
      <template v-slot:actions="{ hide, submit }">
        <MButton
          id="create-and-relate-btn"
          outline
          :disabled="
            selectedRelationType === $constants.SERVICE_CATALOG &&
            !creatingResource.serviceId
          "
          class="mx-1"
          :loading="processing"
          @click="submit"
        >
          {{ $t('create_and_relate') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </div>
</template>

<script>
import Omit from 'lodash/omit'
import Invert from 'lodash/invert'
import TicketSelectionList from '@components/item-selection-list/ticket-list'
import KnowledgeSelectionList from '@components/item-selection-list/knowledge-list'
import TicketForm from '@modules/ticket/components/ticket-form'
import ServiceCatalogForm from '@modules/service-catalog/views/service-catalog-form'
import AssetForm from '@modules/asset/components/asset-form'
import KnowledgeForm from '@modules/knowledge/components/knowledge-form'
import ContractSelectionList from '@components/item-selection-list/contract-list'
import PurchaseSelectionList from '@components/item-selection-list/purchase-list'
import ProjectSelectionList from '@components/item-selection-list/project-list'
import AssetSelectionContainer from './asset-selection-container'
import RelationContent from './relation-content'
import { transformTicketForList } from '@data/ticket'
import { transformAssetForList } from '@data/asset'
import { transformKnowledge } from '@data/knowledge'
import { transformContract } from '@data/contract'
import { transformPurchaseForList } from '@data/purchase'
import { transformProjectForList } from '@data/project'
import { LicenseComputed } from '@state/modules/license'
import { getAssetTypeRestFromModule } from '@modules/asset/helpers/asset-type'
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
import {
  getRelationsApi,
  bulkAddRelationApi,
  createAndRelateApi,
  getEligableRelationApi,
  getRelationCountApi,
} from './relation-api'

export default {
  name: 'RelationContainer',
  components: { RelationContent, FormRulesProvider },
  props: {
    allowedModules: {
      type: Array,
      default() {
        return []
      },
    },
    excludedModules: {
      type: Array,
      default() {
        return []
      },
    },
    resourceId: {
      type: Number,
      required: true,
    },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
    moduleName: {
      type: String,
      required: true,
    },
    subModuleName: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultActive: {
      type: String,
      default: undefined,
    },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
  },
  data() {
    this.assetSubmoduleNameMap = Invert(getAssetTypeRestFromModule)
    this.allAvailableTabs = [
      {
        moduleName: this.$constants.REQUEST,
        title: this.$tc('incident'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.REQUEST) >= 0,
      },
      {
        moduleName: this.$constants.SERVICE_CATALOG,
        title: `${this.$tc('request')} ${this.$tc('service')}`,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.SERVICE_CATALOG) >= 0,
      },
      {
        moduleName: this.$constants.PROBLEM,
        title: this.$tc('problem'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.PROBLEM) >= 0,
      },
      {
        moduleName: this.$constants.CHANGE,
        title: this.$tc('change'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.CHANGE) >= 0,
      },
      ...(this.moduleName !== this.$constants.PROJECT
        ? [
            {
              moduleName: this.$constants.RELEASE,
              title: this.$tc('release'),
              isModuleAvailable: (allAvailableModule) =>
                allAvailableModule.indexOf(this.$constants.RELEASE) >= 0,
            },
          ]
        : []),
      ...(this.moduleName !== this.$constants.ASSET
        ? [
            {
              moduleName: this.$constants.ASSET,
              title: this.$tc('asset'),
              isModuleAvailable: (allAvailableModule) =>
                allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
            },
          ]
        : [
            {
              moduleName: this.$constants.CONTRACT,
              title: this.$tc('contract'),
              isModuleAvailable: (allAvailableModule) =>
                allAvailableModule.indexOf(this.$constants.CONTRACT) >= 0,
            },
          ]),
      {
        moduleName: this.$constants.KNOWLEDGE,
        title: this.$tc('knowledge'),
      },
      ...(this.moduleName === this.$constants.REQUEST
        ? [
            {
              moduleName: 'merged',
              title: `${this.$tc('merged')} ${this.$tc('request')}`,
            },
          ]
        : []),
      // add purchase tan for request and asset (hardware, non it) only
      ...(this.moduleName === this.$constants.REQUEST ||
      this.subModuleName === this.$constants.ASSET_HARDWARE ||
      this.subModuleName === this.$constants.ASSET_NON_IT ||
      this.moduleName === this.$constants.PROJECT
        ? [
            {
              moduleName: this.$constants.PURCHASE,
              title: this.$tc('purchase'),
              isModuleAvailable: (allAvailableModule) =>
                allAvailableModule.indexOf(this.$constants.PURCHASE) >= 0,
            },
          ]
        : []),
      ...(this.moduleName !== this.$constants.RELEASE &&
      this.moduleName !== this.$constants.CONTRACT &&
      this.moduleName !== this.$constants.PROJECT
        ? [
            {
              moduleName: this.$constants.PROJECT,
              title: this.$tc('project'),
              isModuleAvailable: (allAvailableModule) =>
                allAvailableModule.indexOf(this.$constants.PROJECT) >= 0,
            },
          ]
        : []),
    ]
    return {
      renderCount: 1,
      renderCreateFormCount: 1,
      showDrawer: false,
      showCreateDrawer: false,
      selectedRelationType: null,
      selectedItems: [],
      processing: false,
      currentTab: this.defaultActive,
      alreadyRelatedIds: [],
      creatingResource: {},
      searchAffixed: false,
      selectedAssetSubModuleName: null,
      copyDataFromParent: false,
      parentRequestSelectedAssets: [],
      relationCount: {},
    }
  },
  computed: {
    ...LicenseComputed,
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
    allowCopyParentData() {
      return (
        this.selectedRelationType === this.$constants.REQUEST &&
        this.moduleName === this.$constants.REQUEST
      )
    },
    allTabs() {
      let allAvailableTabs = this.allAvailableTabs
      if (this.moduleName === this.$constants.CONTRACT) {
        allAvailableTabs = this.allAvailableTabs.filter(
          (tab) => tab.moduleName === this.$constants.ASSET
        )
      }
      if (this.moduleName === this.$constants.PURCHASE) {
        allAvailableTabs = this.allAvailableTabs.filter(
          (tab) =>
            [
              this.$constants.REQUEST,
              this.$constants.ASSET,
              this.$constants.PROJECT,
            ].indexOf(tab.moduleName) >= 0
        )
      }
      return allAvailableTabs.filter((t) => {
        if (t.isModuleAvailable) {
          return t.isModuleAvailable(this.availableModulesInLicense)
        }
        return true
      })
    },
    selectionComponent() {
      if (
        [
          this.$constants.REQUEST,
          this.$constants.PROBLEM,
          this.$constants.CHANGE,
          this.$constants.RELEASE,
        ].indexOf(this.selectedRelationType) >= 0
      ) {
        return TicketSelectionList
      }
      if (this.selectedRelationType === this.$constants.KNOWLEDGE) {
        return KnowledgeSelectionList
      }
      if (this.selectedRelationType === this.$constants.ASSET) {
        return AssetSelectionContainer
      }
      if (this.selectedRelationType === this.$constants.CONTRACT) {
        return ContractSelectionList
      }
      if (this.selectedRelationType === this.$constants.PURCHASE) {
        return PurchaseSelectionList
      }
      if (this.selectedRelationType === this.$constants.PROJECT) {
        return ProjectSelectionList
      }
      return 'template'
    },
    formComponent() {
      if (
        [
          this.$constants.REQUEST,
          this.$constants.PROBLEM,
          this.$constants.CHANGE,
          this.$constants.RELEASE,
        ].indexOf(this.selectedRelationType) >= 0
      ) {
        return TicketForm
      }
      if (this.selectedRelationType === this.$constants.SERVICE_CATALOG) {
        return ServiceCatalogForm
      }
      if (this.selectedRelationType === this.$constants.KNOWLEDGE) {
        return KnowledgeForm
      }
      if (this.selectedRelationType === this.$constants.ASSET) {
        return AssetForm
      }
      return 'template'
    },
    addRelationOptions() {
      return this.addRelationTypes
        .filter((o) => o.moduleName !== this.$constants.SERVICE_CATALOG)
        .map((o) => ({
          text:
            o.moduleName === this.$constants.REQUEST
              ? this.$tc('request')
              : o.title,
          value: o.moduleName,
          key: o.moduleName,
        }))
    },
    createRelationOptions() {
      if (this.moduleName === this.$constants.PURCHASE) {
        return []
      }
      return this.addRelationTypes
        .filter(
          (t) =>
            t.moduleName !== this.$constants.KNOWLEDGE &&
            t.moduleName !== this.$constants.CONTRACT &&
            t.moduleName !== this.$constants.PURCHASE &&
            t.moduleName !== this.$constants.PROJECT
        )
        .map((o) => ({
          text: o.title,
          value: o.moduleName,
          key: o.moduleName,
        }))
    },
    // allExcludeId() {
    //   let ids = []
    //   if (this.currentTab === this.moduleName) {
    //     ids.push(this.resourceId)
    //   }
    //   if (this.currentTab === this.selectedRelationType) {
    //     ids = ids.concat(...this.alreadyRelatedIds)
    //   }
    //   return ids
    // },
    tabs() {
      let tabs = this.allTabs
      if (this.allowedModules.length) {
        tabs = tabs.filter(
          (t) => this.allowedModules.indexOf(t.moduleName) >= 0
        )
      }
      if (this.excludedModules.length) {
        tabs = tabs.filter(
          (t) => this.excludedModules.indexOf(t.moduleName) < 0
        )
      }
      return tabs
    },
    leftSideTabOptions() {
      return this.tabs
        .filter((tab) => tab.moduleName !== this.$constants.SERVICE_CATALOG)
        .map((tab) => ({
          ...tab,
          title:
            tab.moduleName === this.$constants.REQUEST
              ? this.$tc('request')
              : tab.title,
        }))
    },
    addRelationTypes() {
      if (this.currentTab === 'merged') {
        return []
      }
      return this.tabs.filter(({ moduleName }) => moduleName !== 'merged')
    },
  },
  watch: {
    tabs: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentTab =
            this.currentTab ||
            ((this.tabs || [])[0] || {}).moduleName ||
            this.$constants.REQUEST
        }
      },
    },
  },
  created() {
    this.getParentRequesteLinkAssets()
    this.getRelationModuleCount()
  },
  methods: {
    handleResetForm(reRenderForm = true) {
      this.creatingResource = {}
      if (reRenderForm) {
        this.renderCreateFormCount++
      }
    },
    getRelationModuleCount() {
      const moduleName = this.subModuleName || this.moduleName
      return getRelationCountApi(moduleName, this.resourceId).then((data) => {
        this.relationCount = data
      })
    },
    alreadyRelatedItemsReceived(items) {
      if (
        this.currentTab === this.$constants.ASSET &&
        this.moduleName === this.$constants.REQUEST
      ) {
        this.parentRequestSelectedAssets = items.map((i) =>
          transformAssetForList({ ...i, relationId: null })
        )
      }
    },
    getParentRequesteLinkAssets() {
      if (this.hasAssetModule && this.moduleName === this.$constants.REQUEST) {
        return getRelationsApi(
          this.$constants.REQUEST,
          this.resourceId,
          this.$constants.ASSET,
          'related'
        ).then((data) => {
          this.parentRequestSelectedAssets = data.items.map((i) =>
            transformAssetForList({ ...i, relationId: null })
          )
        })
      }
    },
    changeCopyDataFromParent(checked) {
      this.copyDataFromParent = checked
      if (checked) {
        const resource = Omit(this.resource, ['id'])
        delete resource.sourceId
        this.creatingResource = {
          ...resource,
          linkAssetIds: this.parentRequestSelectedAssets.map(
            (a) => `${a.id}:${a.model}`
          ),
        }
        this.renderCreateFormCount++
      } else {
        this.creatingResource = {}
        this.renderCreateFormCount++
      }
    },
    handleTemplateSelected(template) {
      if (!Object.keys(template).length) {
        this.creatingResource = {}
      }
    },
    handleChangeCreatingResource(data) {
      this.creatingResource = {
        ...this.creatingResource,
        ...data,
      }
    },
    transformFn() {
      if (
        [this.$constants.REQUEST, this.$constants.PROBLEM].indexOf(
          this.selectedRelationType
        ) >= 0
      ) {
        return transformTicketForList
      }
      if (this.selectedRelationType === this.$constants.KNOWLEDGE) {
        return transformKnowledge
      }
      if (this.selectedRelationType === this.$constants.ASSET) {
        return transformAssetForList
      }
      if (this.selectedRelationType === this.$constants.CONTRACT) {
        return transformContract
      }
      if (this.selectedRelationType === this.$constants.PURCHASE) {
        return transformPurchaseForList
      }
      if (this.selectedRelationType === this.$constants.PROJECT) {
        return transformProjectForList
      }
      return (e) => e
    },
    fetchEligableRelations(targetModuleName, criterias, limit, offset) {
      return getEligableRelationApi(
        {
          moduleName: this.subModuleName || this.moduleName,
          id: this.resourceId,
        },
        targetModuleName || this.selectedRelationType,
        criterias,
        limit,
        offset
      ).then((data) => {
        return {
          ...data,
          items: data.items.map(this.transformFn()),
        }
      })
    },
    createAndRelateNew(type) {
      this.selectedRelationType = type
      this.$refs.createAndRelateDropdown.hide()
      setTimeout(() => {
        this.showCreateDrawer = true
        if (
          type === this.$constants.REQUEST &&
          this.moduleName === this.$constants.REQUEST
        ) {
          this.changeCopyDataFromParent(true)
        }
      }, 250)
    },
    addNewRelation(type) {
      this.selectedRelationType = type
      this.$refs.addDropdown.hide()
      setTimeout(() => {
        this.showDrawer = true
      }, 250)
    },
    handleAddRelations() {
      if (this.processing) {
        return
      }
      this.processing = true
      // for asset set childmodule like asset_hardware, asset_software and asset_non_it as selected relation type
      const selectedRelationType =
        this.selectedRelationType === this.$constants.ASSET
          ? this.selectedAssetSubModuleName
          : this.selectedRelationType
      // for asset set childmodule like asset_hardware, asset_software and asset_non_it
      const moduleName = this.subModuleName || this.moduleName
      bulkAddRelationApi(
        moduleName,
        this.resourceId,
        selectedRelationType,
        this.selectedItems.map(({ id }) => id)
      )
        .then((data) => {
          // immediate display identify as proble tag
          if (
            this.moduleName === this.$constants.REQUEST &&
            this.selectedRelationType === this.$constants.PROBLEM
          ) {
            this.$emit('refresh')
          }
          // immediate display purchase request tag
          if (
            this.moduleName === this.$constants.REQUEST &&
            this.selectedRelationType === this.$constants.PURCHASE
          ) {
            this.$emit('refresh')
          }
          if (this.selectedRelationType === this.moduleName) {
            this.$emit('refresh')
          }
          if (this.selectedRelationType === this.currentTab) {
            this.renderCount += 1
          }
          this.currentTab = this.selectedRelationType
          this.$emit('relation-added', this.selectedRelationType)
          this.hideDrawer()
          this.getRelationModuleCount()
        })
        .finally(() => {
          setTimeout(() => {
            this.processing = false
          }, 250)
        })
    },
    hideDrawer() {
      this.selectedRelationType = null
      this.selectedAssetSubModuleName = null
      this.selectedItems = []
      this.showDrawer = false
      this.showCreateDrawer = false
      this.copyDataFromParent = false
      this.creatingResource = {}
    },
    setSelectedItems(items) {
      this.selectedItems = items
    },
    handleAssetSubModuleChange(subModuleName) {
      this.selectedAssetSubModuleName = subModuleName
    },
    handleCreateAndRelate() {
      if (this.processing) {
        return
      }
      const moduleName =
        this.selectedRelationType === this.$constants.ASSET
          ? this.assetSubmoduleNameMap[this.creatingResource.type]
          : this.selectedRelationType
      const sourceModuleName = this.subModuleName || this.moduleName
      this.processing = true
      createAndRelateApi(
        sourceModuleName,
        this.resourceId,
        {
          ...this.creatingResource,
          ...(moduleName === this.$constants.SERVICE_CATALOG
            ? { serviceId: undefined }
            : {}),
          moduleName,
        },
        moduleName === this.$constants.SERVICE_CATALOG
          ? this.creatingResource.serviceId
          : undefined
      )
        .then(() => {
          // immediate display identify as proble tag
          if (
            this.moduleName === this.$constants.REQUEST &&
            this.selectedRelationType === this.$constants.PROBLEM
          ) {
            this.$emit('refresh')
          }
          // immediate display purchase request tag
          if (
            this.moduleName === this.$constants.REQUEST &&
            this.selectedRelationType === this.$constants.PURCHASE
          ) {
            this.$emit('refresh')
          }
          if (this.selectedRelationType === this.moduleName) {
            this.$emit('refresh')
          }
          if (this.selectedRelationType === this.currentTab) {
            this.renderCount += 1
          }
          if (
            this.selectedRelationType === this.$constants.SERVICE_CATALOG &&
            this.currentTab === this.$constants.REQUEST
          ) {
            this.renderCount += 1
          }
          this.currentTab =
            this.selectedRelationType === this.$constants.SERVICE_CATALOG
              ? this.$constants.REQUEST
              : this.selectedRelationType
          this.hideDrawer()
          this.creatingResource = {}
          this.getRelationModuleCount()
          this.$emit('relation-added')
        })
        .finally(() => {
          setTimeout(() => {
            this.processing = false
          }, 300)
        })
    },
    relationRemoved(event) {
      if (
        event.sourceModuleName === this.$constants.REQUEST &&
        event.destinationModuleName === this.$constants.PROBLEM
      ) {
        this.$emit('refresh')
      }
      if (
        event.sourceModuleName === this.$constants.REQUEST &&
        event.destinationModuleName === this.$constants.PURCHASE
      ) {
        this.$emit('refresh')
      }
      this.getRelationModuleCount()
      this.$emit('relation-removed')
    },
  },
}
</script>
<style lang="less">
.relation-header {
  background-color: var(--page-background-color);
}
</style>
