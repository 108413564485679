<template>
  <FlotoCrudContainer
    ref="crudContainerRef"
    :fetch-fn="getWorkLogs"
    :update-fn="updateWorkLog"
    :scrollable="false"
    :create-fn="addWorkLog"
    :delete-fn="removeWorkLog"
    :default-item="defaultWorklog"
    :columns="tableColumns"
    :show-no-data="false"
    as-table
  >
    <template v-slot:add-controls="{ create }">
      <Component
        :is="stickySearch ? 'MAffix' : 'div'"
        v-if="!disabled"
        :offset-top="offsetTop"
        @change="searchAffixed = $event"
      >
        <div :class="{ 'pt-2': searchAffixed }" class="sticky-tab-header">
          <MRow>
            <MCol class="m-align-button">
              <slot name="before-add-controls"></slot>
              <MButton id="add-worklog-btn" variant="neutral" @click="create">
                {{ $t('add') }} {{ $tc('work_log') }}
              </MButton>
            </MCol>
          </MRow>
          <MDivider />
        </div>
      </Component>
    </template>
    <template v-slot:form-header="{ item }">
      {{ $t(item.id ? 'edit' : 'add') }} {{ $tc('work_log') }}
    </template>
    <template v-slot:form-items="{ item: worklog }">
      <WorkLogForm :worklog="worklog" :group-id="groupId" />
    </template>
    <template v-slot:form-actions="{ submit, cancel, item, processing }">
      <MButton
        id="add-update-btn"
        class="mx-1"
        :loading="processing"
        @click="submit"
      >
        {{ $t(item.id ? 'update' : 'add') }}
      </MButton>
      <MButton id="cancel-btn" variant="default" @click="cancel">
        {{ $t('cancel') }}
      </MButton>
    </template>
    <template v-slot:technicianId="{ item }">
      <td>
        <FlotoTechnicianPicker
          :value="item.technicianId"
          class="cursor-default"
          disabled
          placeholder="---"
        />
      </td>
    </template>
    <template v-slot:startDate="{ item }">
      <td class="text-ellipsis">{{ item.timeRange.startTime | datetime }}</td>
    </template>
    <template v-slot:endDate="{ item }">
      <td class="text-ellipsis">{{ item.timeRange.endTime | datetime }}</td>
    </template>
    <template v-slot:timeTaken="{ item }">
      <td class="text-ellipsis">{{ calculateTimeTaken(item) }}</td>
    </template>
    <template v-slot:description="{ item }">
      <MTooltip :disabled="item.description.length <= 20">
        <template v-slot:trigger>
          <td class="text-ellipsis">{{ item.description }}</td>
        </template>
        {{ item.description }}
      </MTooltip>
    </template>
    <template v-slot:actions="{ item, edit, remove }">
      <td>
        <MTooltip>
          <template v-slot:trigger>
            <a class="text-neutral" @click="edit">
              <MIcon name="pencil" />
            </a>
          </template>
          {{ $t('edit') }}
        </MTooltip>
        <FlotoDeleteBtn
          class="mx-2"
          :message="$t('confirm_remove_item', { item: $t('work_log') })"
          @confirm="remove"
        />
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import Moment from 'moment'
import Bus from '@utils/emitter'
import { authComputed } from '@state/modules/auth'
import WorkLogForm from './worklog-form'
import {
  getWorklogApi,
  getProjectWorklogApi,
  createWorklogApi,
  updateWorklogApi,
  deleteWorklogApi,
} from './api'

export default {
  name: 'TimeLogContainer',
  components: { WorkLogForm },
  props: {
    disabled: { type: Boolean, default: false },
    resourceId: { type: Number, required: true },
    groupId: { type: Number, default: 0 },
    moduleName: { type: String, required: true },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
  },
  data() {
    return {
      searchAffixed: false,
    }
  },
  computed: {
    ...authComputed,
    tableColumns() {
      return [
        { name: `${this.$tc('technician')}`, key: 'technicianId' },
        { name: `${this.$t('start')} ${this.$t('date')}`, key: 'startDate' },
        { name: `${this.$t('end')} ${this.$t('date')}`, key: 'endDate' },
        { name: `${this.$t('time_taken')}`, key: 'timeTaken' },
        { name: `${this.$t('description')}`, key: 'description' },
        ...(this.disabled
          ? []
          : [{ name: this.$tc('action', 2), key: 'actions', width: '5rem' }]),
      ]
    },
    defaultWorklog() {
      return {
        technicianId: this.user.id,
        timeRange: {
          startTime:
            Moment()
              .subtract(3, 'hours')
              .unix() * 1000,
          endTime: Moment().unix() * 1000,
        },
      }
    },
  },
  created() {
    const createWorklogHandler = () => {
      this.$refs.crudContainerRef.showCreateForm()
    }
    Bus.$on('create-worklog', createWorklogHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('create-worklog', createWorklogHandler)
    })
  },
  methods: {
    getWorkLogs(limit, offset) {
      // @TODO Apply filters here
      const api =
        this.moduleName === this.$constants.PROJECT
          ? getProjectWorklogApi
          : getWorklogApi
      return api(this.moduleName, this.resourceId, limit, offset)
    },
    addWorkLog(data) {
      return createWorklogApi(this.moduleName, this.resourceId, {
        ...data,
      })
    },
    updateWorkLog(data) {
      return updateWorklogApi(this.moduleName, this.resourceId, data)
    },
    removeWorkLog(data) {
      return deleteWorklogApi(this.moduleName, this.resourceId, data)
    },
    calculateTimeTaken(item) {
      if (item.timeRange) {
        if (item.timeRange.startTime && item.timeRange.endTime) {
          const diff = item.timeRange.endTime - item.timeRange.startTime
          const timeTaken = Math.ceil(
            Moment.duration(diff, 'milliseconds').asHours()
          )
          return `${timeTaken} ${this.$tc('hours', timeTaken)}`
        }
        return 0
      }
      return 0
    },
  },
}
</script>
